/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const auctionRegister = (data: any, banner: any): any => {
  const erros: string[] = [];
  if (!data.des_title || data.des_title === '') {
    erros.push('Informe o titulo');
  }

  if (!data.des_description || data.des_description === '') {
    erros.push('Informe a descrição');
  }

  if (!data.dt_start || data.dt_start === '') {
    erros.push('Informe a data do leilão');
  }

  if (!data.hr_start || data.hr_start === '') {
    erros.push('Informe a hora que começa leilão');
  }

  if (!data.qt_lots || data.qt_lots === '') {
    erros.push('Informe a quantidade de lotes');
  }

  if (!data.des_auctioneer || data.des_auctioneer === '') {
    erros.push('Informe o Leiloeira');
  }

  if (!data.des_organizer || data.des_organizer === '') {
    erros.push('Informe o organizador');
  }

  if (!banner?.name || banner?.name === '') {
    erros.push('É nescessario anexar o banner');
  }

  return erros.join('.');
};

export const auctionUpdate = (data: any): any => {
  const erros: string[] = [];
  if (!data.des_title || data.des_title === '') {
    erros.push('Informe o titulo');
  }

  if (!data.des_description || data.des_description === '') {
    erros.push('Informe a descrição');
  }

  if (!data.dt_start || data.dt_start === '') {
    erros.push('Informe a data do leilão');
  }

  if (!data.hr_start || data.hr_start === '') {
    erros.push('Informe a hora que começa leilão');
  }

  if (!data.qt_lots || data.qt_lots === '') {
    erros.push('Informe a quantidade de lotes');
  }

  if (!data.des_auctioneer || data.des_auctioneer === '') {
    erros.push('Informe o Leiloeira');
  }

  if (!data.des_organizer || data.des_organizer === '') {
    erros.push('Informe o organizador');
  }

  return erros.join('.');
};
