import React from 'react';
import { CircularProgress } from '@material-ui/core';

import useStyles from '../../assets/styles/components/loading';

const Loading: React.FC = () => {
  const classeStyle = useStyles();
  return (
    <>
      <div className={classeStyle.loading} id="loading">
        <CircularProgress disableShrink />
      </div>
    </>
  );
};
export default Loading;
