/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo, useState } from 'react';
import { Button, CssBaseline, Grid, styled } from '@material-ui/core';

import { toast } from 'react-toastify';
import DataCompany from './DataCompany';
import Address from './Address';

import DrawerAuction from '../../../components/DrawerAuction';

import useStyles from '../../../assets/styles/pages/admin/users';
import { useAddressContext } from '../../../providers/use-address';
import logoExmpleImg from '../../../assets/images/exemple_logo.jpeg';
import { api } from '../../../services';
import { useSignInContext } from '../../../providers/use-signin';
import { useCompanyContext } from '../../../providers/use-company';

const CompanyUpdate: React.FC = () => {
  const classeStyle = useStyles();
  const useSignIn = useSignInContext();
  const companyContext = useCompanyContext();
  const addressContext = useAddressContext();
  const [photoLogo, setPhotoLogo] = useState<any>(null);

  const previewImgPorfile: any = useMemo(() => {
    return photoLogo ? URL.createObjectURL(photoLogo) : null;
  }, [photoLogo]);

  const handleChangeFormPhotoLogo = (event: any) => {
    setPhotoLogo(event.target.files[0]);
  };

  const handleSubmitPhotoLogo = async (event: any) => {
    event.preventDefault();

    try {
      if (companyContext.data.company?.PhotoLogo.url) {
        await api.apiLs.company.deletePhotoLogo(
          useSignIn.data?.user?.id_company,
          useSignIn.data?.user?.id_person,
        );
      }

      const dataPhotoLogo = new FormData();

      dataPhotoLogo.append('des_logo_photo', photoLogo);
      dataPhotoLogo.append(
        'id_person',
        String(useSignIn.data?.user?.id_person),
      );
      dataPhotoLogo.append(
        'id_company',
        String(useSignIn.data?.user?.id_company),
      );

      await api.apiLs.company.createPhotoLogo(
        dataPhotoLogo,
        useSignIn.data?.user?.id_company,
        useSignIn.data?.user?.id_person,
      );

      if (useSignIn.data.user.id_person == useSignIn.data?.user?.id_person) {
        await useSignIn.getDataUser(useSignIn.data?.user?.id_person);
      }

      toast.success('Logo atualizada com sucesso');
    } catch (error) {
      toast.error('Ocorreu um erro ao fazer upload da logo');
    }
  };

  const handleGetDataPersonAndAddress = async () => {
    await companyContext.get(
      useSignIn.data?.user?.id_company,
      useSignIn.data?.user?.id_person,
    );
    await addressContext.get(
      useSignIn.data?.user?.id_company,
      useSignIn.data?.user?.id_person,
    );
  };

  useEffect(() => {
    handleGetDataPersonAndAddress();
  }, []);

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <>
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAuction />
        <main className={classeStyle.content}>
          <div className={classeStyle.paper} style={{ marginTop: 80 }}>
            {useSignIn.data?.user?.id_company && (
              <Grid
                item
                container
                spacing={3}
                xs={12}
                className={classeStyle.forms}
              >
                <Grid item xs={12} container justifyContent="center">
                  <Grid item xs={12} container justifyContent="center">
                    <h1>Atualizar logo</h1>
                  </Grid>
                  <img
                    className={classeStyle.avatarImg}
                    src={
                      previewImgPorfile ||
                      (companyContext.data.company?.PhotoLogo.url
                        ? companyContext.data.company?.PhotoLogo.url
                        : logoExmpleImg)
                    }
                    alt="avatar"
                  />
                  <Grid item xs={12} container justifyContent="center">
                    <label htmlFor="contained-button-file-img">
                      <Input
                        accept="image/*"
                        onChange={handleChangeFormPhotoLogo}
                        id="contained-button-file-img"
                        multiple
                        type="file"
                      />
                      <Button
                        variant="contained"
                        className={classeStyle.btnUploadLink}
                        component="span"
                      >
                        atualizar logo
                      </Button>
                    </label>
                  </Grid>
                  {previewImgPorfile && (
                    <Button
                      variant="contained"
                      className={classeStyle.btnUpload}
                      component="span"
                      onClick={handleSubmitPhotoLogo}
                    >
                      Enviar foto
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <DataCompany />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Address />
                </Grid>
              </Grid>
            )}
          </div>
        </main>
      </div>
    </>
  );
};

export default CompanyUpdate;
