import * as React from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../pages/SignIn';
import TermsPrivacyPolicy from '../pages/TermsPrivacyPolicy';

const AppRouter: React.FC = () => {
  return (
    <>
      <Route exact path="/" component={SignIn} />
      <Route
        exact
        path="/auction/data/lot/catalog/virtual/:id_auction"
        component={SignIn}
      />
      <Route
        exact
        path="/terms/privacy/policy/:id_company"
        component={TermsPrivacyPolicy}
      />
    </>
  );
};

export default AppRouter;
