/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-children-prop */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';

import { toast, ToastContentProps } from 'react-toastify';

import useStyles from '../../../assets/styles/pages/admin/users';
import { IUserPassword, useUserContext } from '../../../providers/use-user';
import { FormatError, ViewErrorToast } from '../../../helpers/formatting';
import { passwordUpdate } from '../../../helpers/isValidData/user-update';

const Password: React.FC = () => {
  const classeStyle = useStyles();
  const userContext = useUserContext();

  const [dataUpdate, setDataUpdate] = useState<IUserPassword>({
    id_person: userContext.data?.user?.id_person,
    des_password_new: '',
    des_password_new_confirm: '',
  });

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const errors = await passwordUpdate(dataUpdate, 'inadmin');

    if (errors.length > 0) {
      await ViewErrorToast(FormatError(errors));
      return;
    }

    userContext.updatePassword(dataUpdate);
  };

  const handleChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataUpdate((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  useEffect(() => {
    if (userContext.data?.msgError) {
      const errorFormat = FormatError(userContext.data?.msgError);
      errorFormat.forEach(
        (
          element:
            | boolean
            | React.ReactChild
            | React.ReactFragment
            | React.ReactPortal
            | ((props: ToastContentProps) => React.ReactNode)
            | null
            | undefined,
        ) => {
          toast.error(element);
        },
      );
    }
  }, [userContext?.data?.msgError]);

  useEffect(() => {
    if (userContext.data?.msgSuccess) {
      setDataUpdate((field) => {
        return {
          ...field,
          des_password_new: '',
          des_password_new_confirm: '',
        };
      });
      toast.success(userContext.data?.msgSuccess);
    }
  }, [userContext.data]);

  return (
    <>
      <div className={classeStyle.root}>
        <Container component="main" maxWidth="xs" id="password">
          <div>
            <Typography component="h1" variant="h5">
              Atualizar senha
            </Typography>
            <form
              onSubmit={handleSubmit}
              className={classeStyle.form}
              noValidate
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="des_password_new"
                    label="Nova senha"
                    type="password"
                    id="des_password_new"
                    autoComplete="current-password"
                    onChange={handleChangeForm}
                    value={dataUpdate.des_password_new}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="des_password_new_confirm"
                    label="Confirma nova senha"
                    type="password"
                    id="des_password_new_confirm"
                    autoComplete="current-password"
                    onChange={handleChangeForm}
                    value={dataUpdate.des_password_new_confirm}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={`${classeStyle.submit}`}
              >
                Atualizar
              </Button>
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Password;
