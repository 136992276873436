/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, {
  useContext,
  createContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { api } from '../../services';
import { useLoadingContext } from '../use-loading';

interface IparamLogin {
  des_username: string;
  des_password: string;
}

interface iResult {
  status: number;
  data: {
    message: {
      user: IUser;
      token: string;
    };
  };
}

interface IUser {
  id_person: number;
  des_person: string;
  des_lest_person: string;
  nr_cpf: string;
  nr_rg: string;
  nr_phone: string;
  dt_birth: string;
  account: {
    id_account: number;
    des_email: string;
    des_type_user: [] | any;
    nr_policy_terms: number;
  };
  company: {
    des_company_fantasy: string;
    dataInstance: {
      id_data_instance?: number;
      id_session?: number;
      token?: string;
      dt_start?: string;
      dt_end?: string;
      nr_status?: number;
    };
  };
}

type IData = {
  message?: {
    user?: IUser | any;
  };
  user?: IUser | any;
  signedLoading?: boolean;
  msgError?: string;
  statusCode?: number;
};

type ISignInContext = {
  data: IData;
  signed: boolean;
  signing(paramLogin: IparamLogin): Promise<void>;
  signingMaisdelivery(token: string, id_person: IUser | any): Promise<void>;
  getDataUser(id_person: IUser | any): Promise<void>;
  signingOut(): Promise<void>;
};
const SignInContext = createContext<Partial<ISignInContext>>({});

export const UserSignInProvider: React.FC = ({ children }: any) => {
  const { createloading } = useLoadingContext();

  const [data, setData] = useState<IData>({
    signedLoading: false,
    message: {
      user: null,
    },
    user: null,
    msgError: '',
    statusCode: 0,
  });

  const dataUserMemory = useCallback(() => {
    return new Promise((resolve) => {
      const dataUserStorage = JSON.parse(
        localStorage.getItem('@leiloae/stl/user') as string,
      );
      if (dataUserStorage) {
        setData({
          user: dataUserStorage,
        });
      }
      resolve(true);
    });
  }, []);

  useEffect(() => {
    (async () => {
      await dataUserMemory();
    })();
  }, []);

  const signing = async (paramLogin: IparamLogin) => {
    try {
      createloading(true);
      const userData = await api.apiLs.auth.signIn<iResult>(paramLogin);
      setData({
        user: userData.data?.message?.user,
        msgError: '',
      });

      localStorage.setItem(
        '@leiloae/stl/authorization',
        userData.data?.message?.token,
      );
      localStorage.setItem(
        '@leiloae/stl/user',
        JSON.stringify(userData.data?.message?.user),
      );
      createloading(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        createloading(false);
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      createloading(false);
    }
  };

  const getDataUser = async (id_person: IUser) => {
    try {
      const getData = await api.apiLs.auth.getDataUser<iResult>(id_person);

      setData({
        user: getData?.data.message,
      });

      localStorage.setItem(
        '@leiloae/stl/user',
        JSON.stringify(getData?.data?.message),
      );
    } catch (error: any) {
      setData({
        signedLoading: false,
        msgError: error,
      });
    }
  };

  const signingOut = async () => {
    setData({ user: null });
    localStorage.setItem(
      '@leiloae/stl/authorization',
      null as unknown as string,
    );
    localStorage.setItem('@leiloae/stl/user', null as unknown as string);
  };

  const signingMaisdelivery = async (token: string, id_person: IUser) => {
    try {
      createloading(true);

      const getData = await api.apiLs.auth.getDataUser<iResult>(id_person);

      setData({
        user: getData.data?.message,
        msgError: '',
      });

      localStorage.setItem(
        '@leiloae/stl/user',
        JSON.stringify(getData?.data?.message),
      );

      createloading(false);
    } catch (error: any) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        createloading(false);
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      createloading(false);
    }
  };

  return (
    <SignInContext.Provider
      value={{
        data,
        signing,
        getDataUser,
        signingMaisdelivery,
        signed: !!data?.user,
        signingOut,
      }}
    >
      {children}
    </SignInContext.Provider>
  );
};

export const useSignInContext: any = () => {
  return useContext(SignInContext);
};

export const getToken: any = () => {
  return localStorage.getItem('@leiloae/stl/authorization');
};
