/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useContext, createContext, useState } from 'react';

type IData = {
  loading?: boolean;
};

type ILoadingContext = {
  data: IData;
  createloading(param: boolean): Promise<void>;
};
const LoadingContext = createContext<Partial<ILoadingContext>>({});

export const LoadingProvider: React.FC = ({ children }: any) => {
  const [data, setData] = useState<IData>({
    loading: false,
  });

  const createloading = async (param: boolean) => {
    setData({ loading: param });
  };

  return (
    <LoadingContext.Provider
      value={{
        data,
        createloading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext: any = () => {
  return useContext(LoadingContext);
};
