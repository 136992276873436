import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import useStyles from '../../assets/styles/pages/forgotpassword';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const ForgotPassword: React.FC = () => {
  const classeStyles = useStyles();
  return (
    <>
      <Header />
      <Container component="main" maxWidth="xs" id="forgot-password">
        <CssBaseline />
        <div className={classeStyles.paper}>
          <Avatar className={classeStyles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar senha
          </Typography>
          <p>Será enviado e-mail para você com link para recuperar sua senha</p>
          <form className={classeStyles.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="des_username"
              label="E-mail"
              name="des_username"
              autoComplete="des_username"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classeStyles.submit}
            >
              Recuperar
            </Button>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ForgotPassword;
