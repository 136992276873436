import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    footer: {
      padding: 10,
      marginTop: 'auto',
      backgroundColor: '#ece8e8',
      flexGrow: 1,
      zIndex: 13000,
      position: 'relative',
    },
  }),
);

export default useStyles;
