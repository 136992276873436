/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-children-prop */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import DrawerAdmin from '../../../components/DrawerAdmin';
import Footer from '../../../components/Footer';

import useStyles from '../../../assets/styles/pages/signup';
import { useSignUpContext } from '../../../providers/use-signup';
import {
  FormatCep,
  FormatCpf,
  FormatError,
  FormatPhone,
  ViewErrorToast,
} from '../../../helpers/formatting';
import { userSignUp } from '../../../helpers/isValidData/user-sign-up';
import { useAddressContext } from '../../../providers/use-address';

const SignUp: React.FC = () => {
  const classeStyle = useStyles();
  const history = useHistory();
  const addressContext = useAddressContext();

  const [dataSignUp, setDataSignUp] = useState({
    des_person: '',
    des_lest_person: '',
    nr_cpf: '',
    nr_phone: '',
    des_email: '',
    des_password: '',
    des_type_user: 'inadmin',
    nr_policy_terms: 1,
    nr_zip_code: '',
    des_address: '',
    des_number: '',
    des_district: '',
    nr_city: 0,
    nr_state: 0,
  });
  const { signup, data } = useSignUpContext();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const errors = await userSignUp(dataSignUp);

    if (errors.length > 0) {
      await ViewErrorToast(FormatError(errors));
      return;
    }

    await signup(dataSignUp);
  };

  const handleChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataSignUp((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  const handleChangeFormStateAndCity = (name: string, value: string) => {
    setDataSignUp((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  useEffect(() => {
    if (data.msgError) {
      toast.error(data.msgError);
    }
  }, [data.msgError]);

  useEffect(() => {
    if (data.msgSuccess) {
      toast.success(data.msgSuccess);
    }
    if (data.user?.id_person) {
      history.push('/dashboard/admin/users');
    }
  }, [data]);

  useEffect(() => {
    addressContext.indexState();
  }, []);

  useEffect(() => {
    if (dataSignUp.nr_state)
      addressContext.indexSearchCity(dataSignUp.nr_state);
  }, [dataSignUp.nr_state]);

  return (
    <>
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAdmin />
        <main className={classeStyle.content}>
          <div className={classeStyle.paper}>
            <Container component="main" maxWidth="md" id="sign-up">
              <CssBaseline />
              <div className={classeStyle.paper}>
                <form
                  onSubmit={handleSubmit}
                  className={classeStyle.form}
                  noValidate
                >
                  <Grid container>
                    <Grid
                      container
                      item
                      xl={12}
                      md={6}
                      style={{ padding: 5 }}
                      spacing={2}
                    >
                      <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                          Cadastrar usuário
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoComplete="fname"
                          name="des_person"
                          variant="outlined"
                          required
                          fullWidth
                          id="des_person"
                          label="Nome"
                          autoFocus
                          onChange={handleChangeForm}
                          value={dataSignUp.des_person}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="des_lest_person"
                          label="Sobre nome"
                          name="des_lest_person"
                          autoComplete="lname"
                          onChange={handleChangeForm}
                          value={dataSignUp.des_lest_person}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="des_email"
                          label="E-mail"
                          name="des_email"
                          autoComplete="email"
                          onChange={handleChangeForm}
                          value={dataSignUp.des_email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="nr_phone"
                          label="Telefone / WhatsApp"
                          name="nr_phone"
                          autoComplete="phone"
                          onChange={handleChangeForm}
                          value={FormatPhone(dataSignUp.nr_phone)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="nr_cpf"
                          label="CPF"
                          name="nr_cpf"
                          autoComplete="cpf"
                          onChange={handleChangeForm}
                          value={FormatCpf(dataSignUp.nr_cpf)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          name="des_password"
                          label="Senha"
                          type="password"
                          id="des_password"
                          autoComplete="current-password"
                          onChange={handleChangeForm}
                          value={dataSignUp.des_password}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xl={12}
                      md={6}
                      style={{ padding: 5 }}
                      spacing={2}
                    >
                      <Typography component="h1" variant="h5">
                        Endereço
                      </Typography>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="nr_zip_code"
                          label="CEP"
                          name="nr_zip_code"
                          autoComplete="email"
                          onChange={handleChangeForm}
                          value={FormatCep(dataSignUp.nr_zip_code)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <TextField
                          autoComplete="fname"
                          name="des_address"
                          variant="outlined"
                          required
                          fullWidth
                          id="des_address"
                          label="AV/RUA"
                          autoFocus
                          onChange={handleChangeForm}
                          value={dataSignUp.des_address}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="des_number"
                          label="Número"
                          name="des_number"
                          autoComplete="lname"
                          onChange={handleChangeForm}
                          value={dataSignUp.des_number}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant="outlined"
                          required
                          fullWidth
                          id="des_district"
                          label="Bairro"
                          name="des_district"
                          autoComplete="phone"
                          onChange={handleChangeForm}
                          value={dataSignUp.des_district}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {addressContext.dataState?.state && (
                          <Autocomplete
                            id="state"
                            options={addressContext.dataState?.state}
                            getOptionLabel={(option: any) => option.des_uf}
                            value={addressContext?.dataState?.state.find(
                              (dataState: { id_uf: number }) => {
                                return data.id_uf === dataSignUp.nr_state;
                              },
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Estado"
                                value={dataSignUp.nr_state}
                                variant="outlined"
                                name="nr_state"
                                fullWidth
                                required
                              />
                            )}
                            onChange={(event, value) => {
                              handleChangeFormStateAndCity(
                                'nr_state',
                                value?.id_uf,
                              );
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        {addressContext.dataCity?.city && (
                          <Autocomplete
                            id="city"
                            options={addressContext.dataCity?.city}
                            getOptionLabel={(option: any) => option.des_city}
                            value={addressContext?.dataCity?.city.find(
                              (dataCity: { code_city: number }) => {
                                return data.code_city === dataSignUp.nr_city;
                              },
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Cidade"
                                value={dataSignUp.nr_city}
                                variant="outlined"
                                name="nr_city"
                                fullWidth
                                required
                              />
                            )}
                            onChange={(event, value) => {
                              handleChangeFormStateAndCity(
                                'nr_city',
                                value?.code_city,
                              );
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`btn-green ${classeStyle.submit}`}
                  >
                    Cadastrar
                  </Button>
                </form>
              </div>
            </Container>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
