/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useContext, createContext, useState } from 'react';
import { api } from '../../services';

interface iResult {
  status: number;
  data: {
    message: [];
  };
  headers: any;
}

export interface ICompanyResults {
  id_company: number;
  des_fantasy_name: string;
  des_social_name: string;
  nr_cnpj: string;
  nr_state_registration: number;
  nr_phone: string;
  url_site: string;
}

export interface ICompany {
  id_company?: number;
  id_person?: number;
  des_fantasy_name?: string;
  des_social_name?: string;
  nr_cnpj?: string;
  nr_state_registration?: number;
  nr_phone?: string;
  url_site?: string;
  photo?: any;
  created_at?: string;
  updated_at?: string;
}

type IData = {
  message?: {
    user?: ICompanyResults | any;
  };
  company?: ICompanyResults | any;
  headers?: any;
  signedLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type ICompanyContext = {
  data: IData;
  get(id_company: number, id_person: number): Promise<void>;
  indexCount(): Promise<void>;
  update(params: ICompany, type: number): Promise<void>;
};
const CompanyContext = createContext<Partial<ICompanyContext>>({});

export const CompanyProvider: React.FC = ({ children }: any) => {
  const [data, setData] = useState<IData>({
    signedLoading: false,
    message: {},
    company: null,
    headers: null,
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  const cleanMsg = async () => {
    setData((field) => {
      return { ...field, msgError: '', msgSuccess: '' };
    });
  };

  const get = async (id_company: number, id_person: number) => {
    try {
      const userData = await api.apiLs.company.get<iResult>(
        id_company,
        id_person,
      );
      setData({
        company: userData.data?.message,
        msgError: '',
        msgSuccess: '',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        cleanMsg();
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      cleanMsg();
    }
  };

  const update = async (params: ICompany) => {
    try {
      const userData = await api.apiLs.company.update<iResult>(params);

      setData({
        company: userData.data?.message,
        msgError: '',
        msgSuccess: 'Empresa atualizado com sucesso',
      });
      cleanMsg();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        cleanMsg();
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      cleanMsg();
    }
  };

  return (
    <CompanyContext.Provider
      value={{
        data,
        get,
        update,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompanyContext: any = () => {
  return useContext(CompanyContext);
};
