import { createStyles, makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      backgroundColor: '#f0f0f0',
      padding: theme.spacing(0, 1),
    },
    paper: {
      marginTop: 10,
      minHeight: '90vh',
    },
    forms: {
      backgroundColor: '#fff',
      borderRadius: 30,
      padding: 30,
    },
    avatarImg: {
      width: 200,
      height: 200,
      borderRadius: 100,
      objectFit: 'cover',
    },
    row: {
      display: 'flex',
      padding: 5,
    },
    pagination: {
      margin: 10,
      float: 'right',
    },
    avatarDetail: {
      margin: theme.spacing(0, 2),
      backgroundColor: theme.palette.info.dark,
    },
    textDetailPTitle: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 500,
      marginRight: 10,
      color: '#494646',
    },
    textDetailPInfo: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 400,
      color: '#686363',
    },
    textDetailA: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 500,
      color: '#29367f',
      textDecoration: 'none',
    },
    infoNoInstanceText: {
      margin: '10px 0',
      color: '#2b3988',
      fontWeight: 400,
    },
    filterText: {
      color: '#696666',
      margin: 5,
    },
    table: {
      minWidth: 650,
    },
    StatusWhatsApp: {
      margin: theme.spacing(3, 0),
      display: 'flex',
    },
    StatusWhatsAppConnected: {
      color: '#00af0e',
      marginRight: 10,
    },
    StatusWhatsAppDesconected: {
      color: '#ff0202',
    },
    appBar: {
      backgroundColor: '#fff',
      color: '#000',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    btn: {
      margin: 2,
    },
    btnEdit: {
      backgroundColor: '#4cd295',
      borderRadius: 30,
      padding: '5px 20px',
      color: '#fff',
      fontWeight: 700,
    },
    aBtnOpenChat: {
      color: '#3f51b5',
      border: '1px solid rgba(63, 81, 181, 0.5)',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: '4px',
      padding: '10px 15px',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    btnUploadLink: {
      background: 'none',
      fontSize: 11,
      padding: '10px 15px',
      borderRadius: 30,
      color: '#3f51b5',
      margin: 3,
    },
    btnUpload: {
      background: '#4cd295',
      fontSize: 11,
      padding: '10px 15px',
      borderRadius: 30,
      color: '#fff',
      margin: 3,
    },
    chatSection: {
      width: '100%',
      height: '80vh',
    },
    headBG: {
      backgroundColor: '#e0e0e0',
    },
    borderRight500: {
      borderRight: '1px solid #e0e0e0',
    },
    messageArea: {
      height: '70vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    avatarMessageGrid: {
      justifyContent: 'center',
      alignContent: 'Center',
      display: 'flex',
    },
    messageLeft: {
      backgroundColor: '#f2f2f2',
      padding: theme.spacing(1, 2),
    },
    messageRight: {
      backgroundColor: '#c6d8c9',
      padding: theme.spacing(1, 2),
    },
    positionLeft: {
      float: 'left',
    },
    positionRight: {
      float: 'right',
    },
    box: {
      padding: 20,
    },
    boxTotalH1: {
      color: '#000',
      marginLeft: 8,
      fontSize: '3rem',
    },
    boxTotalP: {
      color: '#757575',
      margin: 2,
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    avatarCancel: {
      margin: theme.spacing(1),
      backgroundColor: '#ff0000',
    },
    avatarCancelBot: {
      margin: theme.spacing(1),
      backgroundColor: '#af5d09',
    },
    avatarProgress: {
      margin: theme.spacing(1),
      backgroundColor: '#006eff',
    },
    avatarGreen: {
      margin: theme.spacing(1),
      backgroundColor: '#77a006',
    },
  }),
);

export default useStyles;
