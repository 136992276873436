import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        backgroundColor: '#f0f0f0',
        boxShadow: 'none',
      },
      [theme.breakpoints.up('xs')]: {
        backgroundColor: '#f0f0f0',
        boxShadow: 'none',
      },
    },
    dataPersonAvatar: {
      width: 'auto',
      height: 230,
      margin: 20,
      background: '#f0f0f0',
      borderRadius: 30,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'grid',
    },
    avatarImg: {
      width: 150,
      height: 150,
      borderRadius: 100,
      objectFit: 'cover',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: '#01733f',
    },
    logout: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 20,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    namePanel: {
      height: 70,
      backgroundColor: '#01733f',
      color: '#fff',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      textTransform: 'uppercase',
      fontSize: '1rem',
    },
    menuList: {
      textTransform: 'uppercase',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'grid',
    },
    logo: {
      width: 230,
      marginTop: 50,
    },
  }),
);

export default useStyles;
