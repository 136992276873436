/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api, HttpRequestI } from '../../services';

const name = 'StateListUser';

export interface IListUser {
  id_person?: number;
  des_person?: string;
  des_lest_person?: string;
  nr_cpf?: string;
  nr_phone?: string;
  nr_rg?: string;
  dt_birth?: any;
  account: {
    id_account?: number;
    des_email?: string;
    des_type_user?: [] | any;
    nr_policy_terms?: number;
  };
  company?: {
    id_company?: number;
    id_person?: number;
    des_company?: string;
    des_company_fantasy?: string;
    nr_cnpj?: string;
    nr_state_registration?: string;
    nr_municipal_registration?: string;
    dataInstance?: {
      id_data_instance?: number;
      id_company?: number;
      id_session?: string;
      token?: string;
      dt_start?: string;
      dt_end?: string;
      nr_phone_connect?: string;
      open_hours?: string;
      close_hours?: string;
      nr_status?: number;
    };
  };
}

interface IResponse {
  data: [];
  headers: [] | any;
  selected: IListUser;
}

interface IMessageResponse {
  headers: any;
  data: {
    message: Array<any>;
  };
}

interface IParams {
  page?: number;
  limit?: number;
  id_person?: number;
  id_company?: number;
  filter?: Partial<{
    des_search?: string;
    des_type_user?: string;
  }>;
}

type StateType = IResponse & {
  httprequest?: Partial<HttpRequestI>;
  params?: Partial<IParams>;
};

const initialState: StateType = {
  data: [],
  headers: [],
  selected: {} as IListUser,
  httprequest: { error: false, loading: false, success: false },
  params: { page: 1, limit: 10 } as IParams,
};

const searchListUser = createAsyncThunk(
  `${name}/LIST_USER`,
  async (state?: Partial<IParams>, agrsThunk?: any) => {
    try {
      const result = await api.apiLs.user.indexSearchParams<IMessageResponse>({
        page: state?.page,
        limit: state?.limit,
        ...state?.filter,
      });
      return result;
    } catch (error) {
      return agrsThunk.rejectWithValue(error);
    }
  },
);

const ListUser = createSlice({
  name,
  initialState,
  reducers: {
    getUser: (state, action: PayloadAction<number>) => {
      const userSelected = state.data.find(
        (user: any) => Number(user.id_person) === Number(action.payload),
      );
      if (Boolean(userSelected) === true) {
        localStorage.setItem(
          '@leiloae/stl/user/selected',
          JSON.stringify(userSelected),
        );
      }

      return { ...state, userSelected };
    },
    ParamsUrl: (state, action: PayloadAction<IParams>) => {
      return { ...state, params: action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(searchListUser.pending, (state) => {
      return {
        ...state,
        httprequest: {
          loading: true,
          error: false,
          success: false,
        },
      };
    });
    build.addCase(
      searchListUser.fulfilled,
      (state, action: PayloadAction<IResponse[] | any>) => {
        return {
          ...state,
          data: action.payload.data.message,
          headers: action.payload.headers,
          httprequest: {
            error: false,
            loading: false,
            success: true,
          },
        };
      },
    );
    build.addCase(
      searchListUser.rejected,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          httprequest: {
            error: true,
            loading: false,
            success: false,
            errorMsg: action.payload,
          },
        };
      },
    );
  },
});

export const reducerListUser = ListUser.reducer;
export const actionListUser = { ...ListUser.actions, searchListUser };
