/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Modal,
  TextField,
  Typography,
} from '@material-ui/core';
import { ExcelRenderer } from 'react-excel-renderer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import IconEdit from '@mui/icons-material/Edit';

import { styled } from '@material-ui/styles';
import { toast } from 'react-toastify';
import DrawerAuction from '../../../components/DrawerAuction';
import Loading from '../../../components/Loading';
import Footer from '../../../components/Footer';
import hammerIcon from '../../../assets/images/icons/martelo.png';
import cowBannerExemple from '../../../assets/images/banner_exemple.png';

import useStyles from '../../../assets/styles/pages/admin/auctions';
import { api } from '../../../services';
import { auctionUpdate } from '../../../helpers/isValidData/auction';
import { FormatError, ViewErrorToast } from '../../../helpers/formatting';
import { useSignInContext } from '../../../providers/use-signin';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxHeight: '90%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
};

const AuctionUpdate: React.FC = ({ match }: any) => {
  const { id_auction } = match.params;
  const useSignIn = useSignInContext();

  const classeStyle = useStyles();
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataAuction, setDataAuction] = useState<any>();
  const [dataLots, setDataLots] = useState<any>();
  const [dataUpdateLot, setDataUpdateLot] = useState<any>();
  const [dataArrayLots, setDataArrayLots] = useState<any>([]);
  const [imgBanner, setImgBanner] = useState<any>(null);
  // const [video, setVideo] = useState<any>(null);
  const [catalog, setCatalog] = useState<any>(null);
  const [entryOrder, setEntryOrder] = useState<any>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalUpdateLot, setOpenModalUpdateLot] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const previewImgPorfile: any = useMemo(() => {
    return imgBanner ? URL.createObjectURL(imgBanner) : null;
  }, [imgBanner]);

  const handleChangeFormImg = (event: any) => {
    setImgBanner(event.target.files[0]);
  };

  /* const handleChangeFormVideo = (event: any) => {
    setVideo(event.target.files[0]);
  }; */

  const handleChangeFormCatalog = (event: any) => {
    setCatalog(event.target.files[0]);
  };

  const handleChangeFormEntryOrder = (event: any) => {
    setEntryOrder(event.target.files[0]);
  };

  const fileHandlerXlsx = (event: any) => {
    const fileObj = event.target.files[0];
    if (fileObj.name.split('.')[1] != 'xlsx') {
      toast.error(
        'Arquivo com formato invalido, você precisa importa um arquivo EXCEL com extensão .xlsx',
      );
    }

    ExcelRenderer(fileObj, (err: any, resp: any) => {
      if (err) {
        toast.error(
          'Ocorreu algum erro ao importar o arquivo, por favor tente novamente',
        );
      } else {
        const array: any = [];
        for (let indexRows = 1; indexRows < resp.rows.length; indexRows += 1) {
          const elementRow = resp.rows[indexRows];

          if (
            dataArrayLots.find(
              (dataLot: any) =>
                Number(dataLot.nr_lot) === Number(elementRow[0]),
            )
          ) {
            return toast.error(
              'Número do lote já existe, por favor verifique número de lote da planilha.',
            );
          }

          if (elementRow.length > 0) {
            array.push({
              [`${resp.rows[0][0]}`]: elementRow[0],
              [`${resp.rows[0][1]}`]: elementRow[1],
              [`${resp.rows[0][2]}`]: elementRow[2],
              [`${resp.rows[0][3]}`]: elementRow[3],
              [`${resp.rows[0][4]}`]: elementRow[4],
              [`${resp.rows[0][5]}`]: elementRow[5],
              [`${resp.rows[0][6]}`]: elementRow[6],
              [`${resp.rows[0][7]}`]: elementRow[7],
              [`${resp.rows[0][8]}`]: elementRow[8],
              [`${resp.rows[0][9]}`]: elementRow[9],
            });
          }
        }
        setDataArrayLots([...dataArrayLots, ...array]);
      }
    });
  };

  const handleGetData = async () => {
    try {
      const result: any = await api.apiLs.auction.get(id_auction);
      setDataAuction(result.data.message);

      setDataArrayLots(result.data.message?.lots);
    } catch (error) {
      toast.error('Ocorreu algum erro ao buscar leilão');
    }
  };

  const handleChangeForm = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataAuction((field: any) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  const handleChangeLots = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataLots((field: any) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  const handleUpdateChangeLots = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataUpdateLot((field: any) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  const handleSetLots = () => {
    if (!dataLots) {
      toast.error('Vocẽ precisa preencher formulario de lote para enviar');
      return;
    }

    if (
      dataArrayLots.find(
        (dataLot: any) => Number(dataLot.nr_lot) === Number(dataLots.nr_lot),
      )
    ) {
      return toast.error(
        'Número do lote já existe, por favor escolha outro número de lote.',
      );
    }

    setDataArrayLots([...dataArrayLots, dataLots]);
  };

  const handleSubmitLive = async (nr_status: number) => {
    setLoading(true);

    if (!dataAuction?.des_link_transmition) {
      toast.error('Você precisa informa link de transmissão');
      setLoading(false);
      return;
    }

    try {
      await api.apiLs.auction.update({
        des_link_transmition:
          nr_status == 2 ? dataAuction.des_link_transmition : '',
        id_auction: dataAuction.id_auction,
        dt_start: dataAuction.dt_start,
        hr_start: dataAuction.hr_start,
        hr_end: dataAuction.hr_end,
        nr_status,
      });

      setLoading(false);
      handleCloseModal();
      handleGetData();
      toast.success(
        nr_status == 2
          ? 'Leilão ao vivo com sucesso'
          : 'Leilão finalizado com sucesso',
      );
    } catch (error) {
      setLoading(false);
      toast.error('Ocorreu algum erro ao atualizar o leilão');
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    setBtnDisable(true);
    const errors = await auctionUpdate(dataAuction);

    if (errors.length > 0) {
      setLoading(false);
      setBtnDisable(false);
      await ViewErrorToast(FormatError(errors));
      return;
    }

    try {
      await api.apiLs.auction.update(dataAuction);

      if (dataArrayLots) {
        await api.apiLs.auction.createLots(
          dataAuction.id_auction,
          dataArrayLots,
        );
      }

      if (imgBanner?.name) {
        const dataBanner = new FormData();

        dataBanner.append('des_banner', imgBanner);
        dataBanner.append('id_auction', String(dataAuction.id_auction));
        if (dataAuction.banner?.id_auction_banner) {
          await api.apiLs.auction.deleteBanner(
            id_auction,
            dataAuction.id_person,
            dataAuction.banner.id_auction_banner,
          );
        }
        await api.apiLs.auction.createBanner(
          dataBanner,
          id_auction,
          useSignIn.data.user.id_person,
        );
      }

      /* if (video?.name) {
        const dataVideo = new FormData();

        dataVideo.append('des_video', video);
        dataVideo.append('id_auction', String(dataAuction.id_auction));

        if (dataAuction.video?.id_auction_video) {
          await api.apiLs.auction.deleteVideo(
            id_auction,
            dataAuction.id_person,
            dataAuction.video.id_auction_video,
          );
        }
        await api.apiLs.auction.createVideo(
          dataVideo,
          id_auction,
          useSignIn.data.user.id_person,
        );
      } */

      if (catalog?.name) {
        const dataCatalog = new FormData();

        dataCatalog.append('des_catalog', catalog);
        dataCatalog.append('id_auction', String(dataAuction.id_auction));
        if (dataAuction.catalog.id_auction_catalog) {
          await api.apiLs.auction.deleteCatalog(
            id_auction,
            dataAuction.id_person,
            dataAuction.catalog.id_auction_catalog,
          );
        }
        await api.apiLs.auction.createCatalog(
          dataCatalog,
          id_auction,
          useSignIn.data.user.id_person,
        );
      }

      if (entryOrder?.name) {
        const dataEntryOrder = new FormData();

        dataEntryOrder.append('des_entry_order', entryOrder);
        dataEntryOrder.append('id_auction', String(id_auction));

        if (dataAuction.entryOrder.id_auctions_entry_order) {
          await api.apiLs.auction.deleteEntryOrder(
            id_auction,
            dataAuction.id_person,
            dataAuction.entryOrder.id_auctions_entry_order,
          );
        }

        await api.apiLs.auction.createEntryOrder(
          dataEntryOrder,
          id_auction,
          useSignIn.data.user.id_person,
        );
      }
      setLoading(false);
      setBtnDisable(false);
      handleCloseModal();
      toast.success('Leilão atualizado com sucesso');
    } catch (error: any) {
      setLoading(false);
      setBtnDisable(false);
      if (error.response.data.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Ocorreu algum erro ao atualizar o leilão');
    }
  };

  const handleDeleteLot = async (index: number) => {
    try {
      if (typeof dataArrayLots[index]?.id_auction_lot != 'undefined') {
        await api.apiLs.auction.deleteLot(
          dataAuction?.id_person,
          dataArrayLots[index]?.id_auction_lot,
        );
      }

      dataArrayLots.splice(index, 1);

      setDataArrayLots([...dataArrayLots]);

      toast.success('Lote deletado com sucesso');
    } catch (error) {
      toast.error('Ocorreu algum erro ao deletar o lote');
    }
  };

  const handleIniltUpdateLot = async (index: number) => {
    const lot = dataArrayLots[index];

    lot.index = index;

    setDataUpdateLot(lot);

    setOpenModalUpdateLot(true);
  };

  const handleSubmitUpdadeLot = async () => {
    let result: any;
    setLoading(true);
    try {
      if (!dataUpdateLot) {
        toast.error('Lote não encontrado');
        return;
      }

      if (typeof dataUpdateLot?.id_auction_lot != 'undefined') {
        result = await api.apiLs.auction.updateLot({
          id_person: dataAuction?.id_person,
          ...dataUpdateLot,
        });

        if (result.status !== 200) {
          toast.error('Ocorreu um erro ao atualizar o lote');
          return;
        }
      }

      dataArrayLots[dataUpdateLot.index] = dataUpdateLot;

      setDataUpdateLot(null);
      setDataArrayLots([...dataArrayLots]);
      setOpenModalUpdateLot(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
        return;
      }
      toast.error('Ocorreu algum erro ao editar o lote o lote');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [btnDisable]);

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <>
      {loading && <Loading />}
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAuction />
        <main className={classeStyle.content}>
          <div className={classeStyle.toolbar} />
          <div className={classeStyle.paper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Grid item container>
                <Grid
                  item
                  sm={12}
                  md={8}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <img
                    className={classeStyle.hammerIcon}
                    src={hammerIcon}
                    alt="Leilões"
                  />
                  <h1 className={classeStyle.hammerIconTitle}>
                    Atualizar o cadastro do leilão
                  </h1>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  style={{
                    textAlign: 'center',
                    alignItems: 'center',
                    padding: 5,
                  }}
                >
                  {dataAuction?.nr_status == 2 ? (
                    <h1 className={classeStyle.liveAuctionTitle}>
                      Leilão AO VIVO
                    </h1>
                  ) : dataAuction?.nr_status == 3 ? (
                    <h1
                      className={classeStyle.liveAuctionTitle}
                      style={{ backgroundColor: '#d86f0c' }}
                    >
                      Leilão finalizado
                    </h1>
                  ) : (
                    <h1
                      className={classeStyle.liveAuctionTitle}
                      style={{ backgroundColor: '#09a545' }}
                    >
                      Leilão agendado
                    </h1>
                  )}
                </Grid>
              </Grid>
            </div>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {dataAuction && (
                <form onSubmit={handleSubmit} className={classeStyle.form}>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    spacing={2}
                    style={{ padding: 10 }}
                  >
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={dataAuction.des_title}
                        id="des_title"
                        label="Título:"
                        name="des_title"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        rows={5}
                        multiline
                        variant="outlined"
                        value={dataAuction.des_description}
                        required
                        fullWidth
                        id="des_description"
                        label="Descrição:"
                        name="des_description"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.dt_start}
                        required
                        type="date"
                        fullWidth
                        id="dt_start"
                        label="Data:"
                        name="dt_start"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        value={dataAuction.hr_start}
                        id="hr_start"
                        label="Início:"
                        name="hr_start"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.hr_end}
                        fullWidth
                        id="hr_end"
                        label="Fim:"
                        name="hr_end"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.qt_lots}
                        required
                        fullWidth
                        id="qt_lots"
                        label="Quantidade de lotes:"
                        name="qt_lots"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.qt_installments}
                        fullWidth
                        id="qt_installments"
                        label="Quantidade de parcelas:"
                        name="qt_installments"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.des_races}
                        fullWidth
                        id="des_races"
                        label="Raça(s):"
                        name="des_races"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.des_auctioneer}
                        required
                        fullWidth
                        id="des_auctioneer"
                        label="Leiloeira:"
                        name="des_auctioneer"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.des_location}
                        fullWidth
                        id="des_location"
                        label="Localização:"
                        name="des_location"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.des_organizer}
                        required
                        fullWidth
                        id="des_organizer"
                        label="Organizador:"
                        name="des_organizer"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.id_code_youtube}
                        required
                        fullWidth
                        id="id_code_youtube"
                        label="ID do video do Youtube: P/ propagranda dentro do app"
                        name="id_code_youtube"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        value={dataAuction.des_link_youtube}
                        required
                        fullWidth
                        id="des_link_youtube"
                        label="Link Youtube:"
                        name="des_link_youtube"
                        onChange={handleChangeForm}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    spacing={2}
                    style={{ padding: 10 }}
                  >
                    <Grid item sm={12}>
                      <img
                        className={classeStyle.ImgBanner}
                        src={
                          previewImgPorfile ||
                          (dataAuction.banner.url
                            ? dataAuction.banner.url
                            : cowBannerExemple)
                        }
                        alt="Leilões"
                      />
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <label htmlFor="contained-button-file">
                            <Input
                              onChange={handleChangeFormImg}
                              accept="image/*"
                              id="contained-button-file"
                              multiple
                              type="file"
                            />
                            <Button
                              variant="contained"
                              component="span"
                              className={classeStyle.btnUpload}
                            >
                              Adicionar banner *
                            </Button>
                          </label>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <label htmlFor="contained-button-file-video">
                            <Input
                              accept="video/*"
                              onChange={handleChangeFormVideo}
                              id="contained-button-file-video"
                              multiple
                              type="file"
                            />
                            <Button
                              variant="contained"
                              className={classeStyle.btnUpload}
                              component="span"
                              style={
                                video?.name || dataAuction.video.path
                                  ? { backgroundColor: '#140dda' }
                                  : {}
                              }
                            >
                              Adicionar vídeo *
                            </Button>
                          </label>
                        </Grid> */}
                        <Grid item xs={12}>
                          <label htmlFor="contained-button-file-catalog">
                            <Input
                              accept="application/pdf"
                              onChange={handleChangeFormCatalog}
                              id="contained-button-file-catalog"
                              multiple
                              type="file"
                            />
                            <Button
                              variant="contained"
                              className={classeStyle.btnUpload}
                              component="span"
                              style={
                                catalog?.name || dataAuction.catalog.path
                                  ? { backgroundColor: '#140dda' }
                                  : {}
                              }
                            >
                              Adicionar Catalogo
                            </Button>
                          </label>
                        </Grid>
                        <Grid item xs={12}>
                          <label htmlFor="contained-button-file-entry-order">
                            <Input
                              accept="application/pdf"
                              onChange={handleChangeFormEntryOrder}
                              id="contained-button-file-entry-order"
                              multiple
                              type="file"
                            />
                            <Button
                              variant="contained"
                              className={classeStyle.btnUpload}
                              component="span"
                              style={
                                entryOrder?.name || dataAuction.entryOrder.path
                                  ? { backgroundColor: '#140dda' }
                                  : {}
                              }
                            >
                              Adicionar Ordem de entrada
                            </Button>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item container sm={12} justifyContent="center">
                      {dataAuction.nr_status == 2 ? (
                        <>
                          <Button
                            variant="contained"
                            className={classeStyle.btnUpload}
                            component="span"
                            onClick={handleOpenModal}
                            style={{
                              backgroundColor: '#ee0909',
                              width: '100%',
                              height: 50,
                            }}
                          >
                            Finalizar Transmissão
                          </Button>
                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Tem certeza que deseja finalizar está
                                transmissão?
                              </Typography>
                              <Button
                                variant="contained"
                                className={classeStyle.btnUpload}
                                component="span"
                                onClick={() => handleSubmitLive(3)}
                                style={{
                                  backgroundColor: '#a17006',
                                  width: '100%',
                                }}
                              >
                                Finalizar leilão
                              </Button>
                            </Box>
                          </Modal>
                        </>
                      ) : (
                        <>
                          <Button
                            variant="contained"
                            className={classeStyle.btnUpload}
                            component="span"
                            onClick={handleOpenModal}
                            style={{
                              backgroundColor: '#038022',
                              width: '100%',
                              height: 50,
                            }}
                          >
                            Transmissão ao-vivo
                          </Button>
                          <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Basta informa ID do youtube da transmissão para
                                deixa leilão ao vivo
                              </Typography>
                              <Grid item xs={12} sm={12}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  id="des_link_transmition"
                                  label="ID do youtube transmissão"
                                  name="des_link_transmition"
                                  value={dataAuction.des_link_transmition}
                                  autoComplete="des_link_transmition"
                                  onChange={handleChangeForm}
                                />
                              </Grid>
                              <Button
                                variant="contained"
                                className={classeStyle.btnUpload}
                                component="span"
                                onClick={() => handleSubmitLive(2)}
                                style={{
                                  backgroundColor: '#1c3cee',
                                  width: '100%',
                                }}
                              >
                                Iniciar leilão
                              </Button>
                            </Box>
                          </Modal>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  {dataArrayLots[0] && (
                    <Grid container>
                      <Grid
                        container
                        spacing={2}
                        className={classeStyle.tableLotsTitles}
                      >
                        <Grid item xs={12} sm={12} md={1}>
                          <h3>N° lote:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1}>
                          <h3>LT:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={1}>
                          <h3>QTDE:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <h3>Categoria:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Raça:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Peso (kg):</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Prazo:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Valor Parc:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Vendedor:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={1}>
                          <h3>Localização:</h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={1}>
                          <h3>Ação:</h3>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        className={classeStyle.tableLotsInfo}
                      >
                        {dataArrayLots.map((data: any, index: any) => (
                          <div style={{ display: 'contents' }} key={index}>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                                borderRadius: '30px 0px 0px 30px',
                              }}
                            >
                              <h3>{data.nr_lot || '#'}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.des_lt}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.qt_animals}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={2}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.category}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.race}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.des_weight}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.qt_installments}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.vl_installments}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.des_seller}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={2}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                              }}
                            >
                              <h3>{data?.des_location}</h3>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={3}
                              lg={1}
                              style={{
                                border: 'solid 1px #a39c9c',
                                borderRadius: '0px 30px 30px 0px',
                              }}
                            >
                              <IconButton
                                onClick={() => handleIniltUpdateLot(index)}
                                aria-label="Editar"
                              >
                                <IconEdit style={{ color: '#005bf8' }} />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteLot(index)}
                                aria-label="deletar"
                              >
                                <DeleteIcon style={{ color: '#f00' }} />
                              </IconButton>
                            </Grid>
                          </div>
                        ))}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2} style={{ padding: '10px 20px' }}>
                    <Grid item xs={12} sm={12} md={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="nr_lot"
                        label="N° lote:"
                        name="nr_lot"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="des_lt"
                        label="LT:"
                        name="des_lt"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={1}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="qt_animals"
                        label="QTDE:"
                        name="qt_animals"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="category"
                        label="Categoria:"
                        name="category"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="race"
                        label="Raça:"
                        name="race"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="des_weight"
                        label="Peso (kg):"
                        name="des_weight"
                        autoComplete="des_weight"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="qt_installments"
                        label="Prazo:"
                        name="qt_installments"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="vl_installments"
                        label="Valor Parc:"
                        name="vl_installments"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="des_seller"
                        label="Vendedor:"
                        name="des_seller"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="des_location"
                        label="Localização:"
                        name="des_location"
                        onChange={handleChangeLots}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <Button
                        variant="contained"
                        className={classeStyle.btnUpload}
                        component="span"
                        onClick={handleSetLots}
                        style={{ width: '100%' }}
                      >
                        + Adicionar lote
                      </Button>
                    </Grid>
                  </Grid>
                  <label htmlFor="contained-button-file-xlsx">
                    <Input
                      onChange={fileHandlerXlsx}
                      id="contained-button-file-xlsx"
                      type="file"
                    />
                    <Button
                      variant="contained"
                      component="span"
                      className={classeStyle.btnUpload}
                    >
                      Importar planilha
                    </Button>
                  </label>
                  <button
                    className={classeStyle.btnRegister}
                    disabled={btnDisable}
                    type="submit"
                  >
                    Atualizar
                  </button>
                </form>
              )}
            </Grid>
          </div>
        </main>
      </div>
      <Modal
        open={openModalUpdateLot}
        onClose={() => setOpenModalUpdateLot(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Editar LT: {dataUpdateLot?.des_lt}
          </Typography>
          {dataUpdateLot && (
            <>
              <Grid container spacing={2} style={{ padding: '10px 20px' }}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="nr_lot"
                    label="N° lote:"
                    name="nr_lot"
                    value={dataUpdateLot.nr_lot}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="des_lt"
                    label="LT:"
                    name="des_lt"
                    value={dataUpdateLot.des_lt}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="qt_animals"
                    label="QTDE:"
                    name="qt_animals"
                    value={dataUpdateLot.qt_animals}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="category"
                    label="Categoria:"
                    name="category"
                    value={dataUpdateLot.category}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="race"
                    label="Raça:"
                    name="race"
                    value={dataUpdateLot.race}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="des_weight"
                    label="Peso (kg):"
                    name="des_weight"
                    autoComplete="des_weight"
                    value={dataUpdateLot.des_weight}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="qt_installments"
                    label="Prazo:"
                    name="qt_installments"
                    value={dataUpdateLot.qt_installments}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="vl_installments"
                    label="Valor Parc:"
                    name="vl_installments"
                    value={dataUpdateLot.vl_installments}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="des_seller"
                    label="Vendedor:"
                    name="des_seller"
                    value={dataUpdateLot.des_seller}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="des_location"
                    label="Localização:"
                    name="des_location"
                    value={dataUpdateLot.des_location}
                    onChange={handleUpdateChangeLots}
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                className={classeStyle.btnUpload}
                component="span"
                style={{ width: '100%' }}
                onClick={() => handleSubmitUpdadeLot()}
              >
                Editar lote
              </Button>
            </>
          )}
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default AuctionUpdate;
