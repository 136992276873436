import * as React from 'react';
import { Route } from 'react-router-dom';

import AppRouter from './app.routes';
import ForgotPassword from '../pages/ForgotPassword';
import ForgotPasswordReset from '../pages/ForgotPasswordReset';

const AuthRouter: React.FC = () => {
  return (
    <>
      <Route exact path="/forgot/password" component={ForgotPassword} />
      <Route
        exact
        path="/forgot/password/reset"
        component={ForgotPasswordReset}
      />

      <AppRouter />
    </>
  );
};

export default AuthRouter;
