/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useContext, createContext, useState } from 'react';
import { api } from '../../services';
import { useLoadingContext } from '../use-loading';

interface iResult {
  status: number;
  data: {
    message: [];
  };
}

export interface IAddressResults {
  id_company: number;
  id_address?: number;
  nr_zip_code: string;
  des_address: string;
  des_number: string;
  des_district: string;
  des_reference: string;
  nr_city: number;
  city: [];
  nr_state: number;
  state: [];
}

export interface IAddress {
  id_company: number;
  id_address?: number;
  nr_zip_code: string;
  des_address: string;
  des_number: string;
  des_district: string;
  des_reference: string;
  nr_city: number;
  nr_state: number;
}

type IData = {
  message?: [];
  address?: IAddressResults | any;
  addressLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type IDataSate = {
  message?: [];
  state?: [];
  stateLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type IDataCity = {
  message?: [];
  city?: [];
  cityLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type IAddressContext = {
  data: IData;
  dataState: IDataSate;
  dataCity: IDataCity;
  create(params: IAddress): Promise<void>;
  update(params: IAddress): Promise<void>;
  get(id_company: number, id_person: number): Promise<void>;
  indexState(params?: string): Promise<void>;
  indexSearchCity(id_uf?: number, params?: string): Promise<void>;
};
const AddressContext = createContext<Partial<IAddressContext>>({});

export const AddressProvider: React.FC = ({ children }: any) => {
  const { createloading } = useLoadingContext();

  const [data, setData] = useState<IData>({
    addressLoading: false,
    message: [],
    address: null,
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  const [dataState, setDataState] = useState<IDataSate>({
    stateLoading: false,
    message: [],
    state: [],
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  const [dataCity, setDataCity] = useState<IDataCity>({
    cityLoading: false,
    message: [],
    city: [],
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  const create = async (params: IAddress) => {
    try {
      createloading(true);

      const userData = await api.apiLs.address.create<iResult>(params);

      setData({
        address: userData.data?.message,
        msgError: '',
        msgSuccess: 'Endereço cadastrado com sucesso',
      });
      createloading(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        createloading(false);
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      createloading(false);
    }
  };

  const update = async (params: IAddress) => {
    try {
      const userData = await api.apiLs.address.update<iResult>(params);

      setData({
        address: userData.data?.message,
        msgError: '',
        msgSuccess: 'Endereço atualizado com sucesso',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      createloading(false);
    }
  };

  const get = async (id_company: number, id_person: number) => {
    try {
      const userData = await api.apiLs.address.get<iResult>(
        id_company,
        id_person,
      );

      setData({
        address: userData.data?.message,
        msgError: '',
        msgSuccess: '',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, buscar seu endereço.',
      });
    }
  };

  const indexState = async (params?: string) => {
    try {
      setDataState({ stateLoading: true });
      const userData = await api.apiLs.address.indexState<iResult>(params);

      setDataState({
        state: userData.data?.message,
        stateLoading: false,
        msgError: '',
        msgSuccess: '',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setDataState({
          stateLoading: false,
          msgError: error?.response?.data?.message,
          msgSuccess: '',
        });
        return;
      }
      setDataState({
        stateLoading: false,
        msgError: 'Ocorreu algum erro',
      });
    }
  };

  const indexSearchCity = async (id_uf?: number, params?: string) => {
    try {
      setDataCity({ cityLoading: true });
      const userData = await api.apiLs.address.indexSearchCity<iResult>(
        id_uf,
        params,
      );

      setDataCity({
        city: userData.data?.message,
        cityLoading: false,
        msgError: '',
        msgSuccess: '',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setDataCity({
          cityLoading: false,
          msgError: error?.response?.data?.message,
          msgSuccess: '',
        });
        return;
      }
      setDataCity({
        cityLoading: false,
        msgError: 'Ocorreu algum erro',
      });
    }
  };

  return (
    <AddressContext.Provider
      value={{
        data,
        dataState,
        dataCity,
        create,
        update,
        get,
        indexState,
        indexSearchCity,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export const useAddressContext: any = () => {
  return useContext(AddressContext);
};
