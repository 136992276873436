/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { api } from './config';

export class Auth {
  async signIn<T>(data: any): Promise<T> {
    return api.post('/auth/session/autentication', data);
  }

  async signUp<T>(data: any): Promise<T> {
    return api.post('/user', data);
  }

  async getDataUser<T>(id_person: any): Promise<T> {
    return api.get(`/user/show/${id_person}`);
  }
}
