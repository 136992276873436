/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useContext, createContext, useState } from 'react';
import { api } from '../../services';

interface iResult {
  status: number;
  data: {
    message: [];
  };
  headers: any;
}

export interface Iparam {
  limit?: number;
  page?: number;
  des_search?: any;
  des_type_user?: string;
}

export interface IparamSignIn {
  des_username?: string;
  des_password?: string;
}

export interface IUserResults {
  id_person: number;
  des_person: string;
  des_lest_person: string;
  nr_cpf: string;
  nr_rg: string;
  nr_phone: string;
  dt_birth: string;
  account: {
    id_account: number;
    des_email: string;
    des_type_user: [] | any;
    nr_policy_terms: number;
  };
  company: {
    des_company_fantasy?: string;
  };
}

export interface IUser {
  id_person?: number;
  des_person?: string;
  des_lest_person?: string;
  nr_phone?: string;
  nr_cpf?: string;
  nr_rg?: string;
  des_email?: string;
  des_password?: string;
  des_type_user?: string;
  des_company_fantasy?: string;
  nr_policy_terms?: number;
}

export interface IUserPassword {
  id_person?: number;
  des_password_old?: string;
  des_password_new?: string;
  des_password_new_confirm?: string;
}

type IData = {
  message?: {
    user?: IUserResults | any;
  };
  user?: IUserResults | any;
  countUser?: any;
  headers?: any;
  signedLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type IUserContext = {
  data: IData;
  dataSignIn: IparamSignIn;
  get(id_person: number, id_company: number): Promise<void>;
  indexCount(): Promise<void>;
  update(params: IUser, type: number): Promise<void>;
  updatePassword(params: IUserPassword): Promise<void>;
};
const UserContext = createContext<Partial<IUserContext>>({});

export const UserProvider: React.FC = ({ children }: any) => {
  const [data, setData] = useState<IData>({
    signedLoading: false,
    message: {},
    user: null,
    countUser: null,
    headers: null,
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  const cleanMsg = async () => {
    setData((field) => {
      return { ...field, msgError: '', msgSuccess: '' };
    });
  };

  const get = async (id_person: number, id_company: number) => {
    try {
      const userData = await api.apiLs.user.get<iResult>(id_person, id_company);
      setData({
        user: userData.data?.message,
        msgError: '',
        msgSuccess: '',
      });
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        cleanMsg();
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      cleanMsg();
    }
  };

  const update = async (params: IUser) => {
    try {
      const userData = await api.apiLs.user.update<iResult>(params);

      setData({
        user: userData.data?.message,
        msgError: '',
        msgSuccess: 'Usuário atualizado com sucesso',
      });

      cleanMsg();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        cleanMsg();
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      cleanMsg();
    }
  };

  const updatePassword = async (params: IUserPassword) => {
    try {
      await api.apiLs.user.updatePassword<iResult>(params);

      setData((field) => {
        return {
          ...field,
          msgError: '',
          msgSuccess: 'Senha atualizado com sucesso',
        };
      });
      cleanMsg();
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData((field) => {
          return {
            ...field,
            msgError: error.response.data.message,
          };
        });
        cleanMsg();
        return;
      }
      setData((field) => {
        return {
          ...field,
          msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
        };
      });
      cleanMsg();
    }
  };

  return (
    <UserContext.Provider
      value={{
        data,
        get,
        update,
        updatePassword,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext: any = () => {
  return useContext(UserContext);
};
