import React from 'react';
import { ToastContainer } from 'react-toastify';
import './assets/styles/global.css';
import { Provider } from 'react-redux';

import { LoadingProvider } from './providers/use-loading';
import { UserSignInProvider } from './providers/use-signin';
import { UserSignUpProvider } from './providers/use-signup';
import { UserProvider } from './providers/use-user';
import { CompanyProvider } from './providers/use-company';
import { AddressProvider } from './providers/use-address';
import { store } from './store';

import Routes from './routes';

const App: React.FC = () => {
  return (
    <>
      <ToastContainer />
      <LoadingProvider>
        <UserSignInProvider>
          <UserSignUpProvider>
            <UserProvider>
              <CompanyProvider>
                <AddressProvider>
                  <Provider store={store}>
                    <Routes />
                  </Provider>
                </AddressProvider>
              </CompanyProvider>
            </UserProvider>
          </UserSignUpProvider>
        </UserSignInProvider>
      </LoadingProvider>
    </>
  );
};

export default App;
