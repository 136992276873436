/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { IAddress } from '../../providers/use-address';
import { api } from './config';

export class Address {
  async index<T>(): Promise<T> {
    return api.get(`/address`);
  }

  async get<T>(id_company: number, id_person: number): Promise<T> {
    return api.get(`/address/show/${id_company}/${id_person}`);
  }

  async indexState<T>(data?: string): Promise<T> {
    return api.get(`/address/list/state/search`, { params: data });
  }

  async indexSearchCity<T>(id_uf?: number, data?: string): Promise<T> {
    return api.get(`/address/list/city/search/${id_uf}`, { params: data });
  }

  async create<T>(data: IAddress | any): Promise<T> {
    return api.post(`/address/${data.id_company}`, data);
  }

  async update<T>(data: IAddress): Promise<T> {
    return api.put(`/address/update/${data?.id_company}`, data);
  }
}
