/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionListAuction,
  IListAuction,
} from '../../../store/reduces/ReducerListAuction';
import { RootState } from '../../../store';

import DrawerAdmin from '../../../components/DrawerAdmin';

import Footer from '../../../components/Footer';

import useStyles from '../../../assets/styles/pages/admin/auctions';
import { FormatDatePtBr } from '../../../helpers/formatting';

const Users: React.FC = () => {
  const history = useHistory();
  const classeStyle = useStyles();
  const { reducerListAuction } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    dt_start: '',
  });

  useEffect(() => {
    dispatch(actionListAuction.searchListAuction({ filter: params }));
  }, [params]);

  const handlePage = (url: string) => {
    history.push(url);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (params.page != value) {
      setParams((field) => {
        return {
          ...field,
          page: value,
        };
      });
    }
  };

  const handleChangeForm = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setParams((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  return (
    <>
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAdmin />
        <main className={classeStyle.content}>
          <div className={classeStyle.toolbar} />
          <div className={classeStyle.paper}>
            <p className={classeStyle.filterText}>Filtro</p>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }} variant="outlined">
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Ordenar por data
                  </InputLabel>
                  <Select
                    native
                    value={params.dt_start}
                    name="des_type_user"
                    onChange={handleChangeForm}
                    label="Tipo de usuário"
                    inputProps={{
                      name: 'dt_start',
                      id: 'dt_start',
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value="DESC">Data maior</option>
                    <option value="ASC">Data menor</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                container
                item
                xs={5}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="button"
                  variant="contained"
                  className={classeStyle.btnEdit}
                  onClick={() =>
                    handlePage(`/dashboard/admin/auction/register`)
                  }
                >
                  + Cadastrar leilão
                </Button>
              </Grid>
            </Grid>

            {typeof reducerListAuction.data === 'string' ? (
              <Grid
                container
                item
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 50 }}
              >
                <h1>{reducerListAuction.data}</h1>
              </Grid>
            ) : (
              <>
                {reducerListAuction.data && (
                  <>
                    <TableContainer component={Paper}>
                      <Table
                        className={classeStyle.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="left">Data</TableCell>
                            <TableCell align="left">Título</TableCell>
                            <TableCell align="left">&nbsp;</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reducerListAuction.data.map((row: IListAuction) => (
                            <TableRow key={row.id_auction}>
                              <TableCell component="th" scope="row">
                                {FormatDatePtBr(row.dt_start)} das{' '}
                                {row.hr_start} às {row.hr_end}
                              </TableCell>
                              <TableCell align="center">
                                {row.des_title}
                              </TableCell>

                              <TableCell align="center">
                                <Button
                                  type="button"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() =>
                                    handlePage(
                                      `/dashboard/admin/auction/detail/${row.id_auction}`,
                                    )
                                  }
                                >
                                  Editar
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Pagination
                      className={classeStyle.pagination}
                      page={params.page}
                      count={Math.ceil(
                        reducerListAuction.headers?.xtotalcount / 10,
                      )}
                      onChange={handleChangePage}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Users;
