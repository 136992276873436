/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { IAddress } from '../../providers/use-address';
import { IUser } from '../../providers/use-user';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const userSignUp = (data: IUser | any): any => {
  const erros: string[] = [];

  if (!data.des_person || data.des_person === '') {
    erros.push('Informe seu nome');
  }

  if (!data.des_lest_person || data.des_lest_person === '') {
    erros.push('Informe seu sobre nome');
  }

  if (!data.nr_phone || data.nr_phone === '') {
    erros.push('Informe seu telefone');
  }

  if (!data.des_email || data.des_email === '') {
    erros.push('Informe seu e-mail');
  }

  if (!data.des_password || data.des_password === '') {
    erros.push('Informe sua senha');
  }

  return erros.join('.');
};

export const documentAndCompanySignUp = (data: IUser): any => {
  const erros: string[] = [];

  if (!data.nr_cpf || data.nr_cpf === '') {
    erros.push('Informe seu CPF');
  }

  if (!data.nr_rg || data.nr_rg === '') {
    erros.push('Informe seu RG');
  }

  if (!data.des_company_fantasy || data.des_company_fantasy === '') {
    erros.push('Informe o nome fantasia da sua empresa');
  }

  return erros.join('.');
};

export const addressSignUp = (data: IAddress): any => {
  const erros: string[] = [];

  if (!data.nr_zip_code || data.nr_zip_code === '') {
    erros.push('Informe seu CEP');
  }

  if (!data.des_address || data.des_address === '') {
    erros.push('Informe nome da sua RUA/AV');
  }

  if (!data.des_number || data.des_number === '') {
    erros.push('Informe o número');
  }

  if (!data.des_district || data.des_district === '') {
    erros.push('Informe seu bairro');
  }

  if (!data.nr_city || data.nr_city === null || data.nr_city === 0) {
    erros.push('Informe sua cidade');
  }

  if (!data.nr_state || data.nr_state === null || data.nr_state === 0) {
    erros.push('Informe seu estado');
  }
  return erros.join('.');
};
