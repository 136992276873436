/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api, HttpRequestI } from '../../services';

const name = 'StateListAuction';

export interface IListAuction {
  id_auction?: number;
  id_person?: number;
  des_title?: string;
  des_description?: string;
  dt_start?: any;
  hr_start?: string;
  hr_end?: string;
  qt_lots?: number;
  des_categories?: string;
  des_organizer?: string;
  qt_installments?: number;
  des_link_transmition?: string;
  nr_status?: number;
}

interface IResponse {
  data: [];
  headers: [] | any;
  selected: IListAuction;
}

interface IMessageResponse {
  headers: any;
  data: {
    message: Array<any>;
  };
}

interface IParams {
  page?: number;
  limit?: number;
  filter?: Partial<any>;
}

type StateType = IResponse & {
  httprequest?: Partial<HttpRequestI>;
  params?: Partial<IParams>;
};

const initialState: StateType = {
  data: [],
  headers: [],
  selected: {} as IListAuction,
  httprequest: { error: false, loading: false, success: false },
  params: { page: 1, limit: 10 } as IParams,
};

const searchListAuction = createAsyncThunk(
  `${name}/LIST_AUCTION`,
  async (state?: Partial<IParams>, agrsThunk?: any) => {
    try {
      const result =
        await api.apiLs.auction.indexSearchParams<IMessageResponse>({
          page: state?.page,
          limit: state?.limit,
          ...state?.filter,
        });
      return result;
    } catch (error) {
      return agrsThunk.rejectWithValue(error);
    }
  },
);

const ListAuction = createSlice({
  name,
  initialState,
  reducers: {
    getAcution: (state, action: PayloadAction<number>) => {
      const auctionSelected = state.data.find(
        (auction: any) => Number(auction.id_auction) === Number(action.payload),
      );
      if (Boolean(auctionSelected) === true) {
        localStorage.setItem(
          '@leiloae/stl/auction/selected',
          JSON.stringify(auctionSelected),
        );
      }

      return { ...state, auctionSelected };
    },
    ParamsUrl: (state, action: PayloadAction<IParams>) => {
      return { ...state, params: action.payload };
    },
  },
  extraReducers: (build) => {
    build.addCase(searchListAuction.pending, (state) => {
      return {
        ...state,
        httprequest: {
          loading: true,
          error: false,
          success: false,
        },
      };
    });
    build.addCase(
      searchListAuction.fulfilled,
      (state, action: PayloadAction<IResponse[] | any>) => {
        return {
          ...state,
          data: action.payload.data.message,
          headers: action.payload.headers,
          httprequest: {
            error: false,
            loading: false,
            success: true,
          },
        };
      },
    );
    build.addCase(
      searchListAuction.rejected,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          httprequest: {
            error: true,
            loading: false,
            success: false,
            errorMsg: action.payload,
          },
        };
      },
    );
  },
});

export const reducerListAuction = ListAuction.reducer;
export const actionListAuction = { ...ListAuction.actions, searchListAuction };
