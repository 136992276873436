import { createStyles, makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#f0f0f0',
      height: '100vh',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      [theme.breakpoints.down('xs')]: {
        display: 'block',
      },
      flexGrow: 1,
      padding: theme.spacing(3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      height: 180,
      borderRadius: 30,
      backgroundColor: '#01733f',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 5,
      cursor: 'pointer',
    },
    buttonText: {
      color: '#fff',
      marginBottom: 10,
      textAlign: 'center',
    },
    LinkA: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'grid',
      textDecoration: 'none',
    },
  }),
);

export default useStyles;
