/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable camelcase */

import { toast, ToastContentProps } from 'react-toastify';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const FormatPriceInput = (price: any) => {
  let v = String(price).replace(/\D/g, '');
  v = `${(Number(v) / 100).toFixed(2)}`;
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return v;
};

export const FormatPriceEua = (price: any) => {
  let v = price.replace('.', '');
  v = v.replace(',', '.');
  return v;
};

export const FormatPhone = (phone: any, input = true) => {
  if (phone.length > 11 && input === false) {
    const regex = /^([0-9]{2})([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    const str = String(phone)
      .replace(/[^0-9]/g, '')
      .slice(0, 13);
    return str.replace(regex, '($2) $3-$4');
  }
  if (phone.length > 11 && input === true) {
    const regex = /^([0-9]{2})([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
    const str = String(phone)
      .replace(/[^0-9]/g, '')
      .slice(0, 13);
    return str.replace(regex, '$1 - ($2) $3-$4');
  }
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  const str = String(phone)
    .replace(/[^0-9]/g, '')
    .slice(0, 11);
  return str.replace(regex, '($1) $2-$3');
};

export const FormatCpf = (cpf: any) => {
  const regex = /^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})$/;
  const str = String(cpf)
    .replace(/[^0-9]/g, '')
    .slice(0, 11);
  return str.replace(regex, '$1.$2.$3-$4');
};

export const FormatHoursInput = (hours: string): any => {
  const regex = /^([0-9]{2})([0-9]{2})$/;
  const str = String(hours)
    .replace(/[^0-9]/g, '')
    .slice(0, 4);
  return str.replace(regex, '$1:$2');
};

export const FormatCep = (zipcode: any) => {
  const regex = /^([0-9]{2})([0-9]{3})([0-9]{3})$/;
  const str = String(zipcode)
    .replace(/[^0-9]/g, '')
    .slice(0, 8);
  return str.replace(regex, '$1.$2-$3');
};

export const FormatDate = (date: any) => {
  const regex = /^([0-9]{2})([0-9]{2})([0-9]{4})$/;
  const str = String(date)
    .replace(/[^0-9]/g, '')
    .slice(0, 8);
  return str.replace(regex, '$1/$2/$3');
};

export const FormatDateInput = (date: any) => {
  const regex = /^([0-9]{2})([0-9]{2})([0-9]{4})$/;
  const str = String(date)
    .replace(/[^0-9]/g, '')
    .slice(0, 8);
  return str.replace(regex, '$1-$2-$3');
};

export const FormatDatePtBr = (date: string): any => {
  if (date) {
    return date.substr(0, 10).split('-').reverse().join('/');
  }
  return null;
};

export const FormatHours = (hours: string): any => {
  if (hours) {
    return hours.substr(0, 5);
  }
  return null;
};

export const FormatDateHoursPtBr = (date: string | number | Date): any => {
  if (date) {
    return new Date(date).toLocaleString('pt-br').replace(' ', ' - ');
  }
  return null;
};

export const onlyNumbers = (str: string): string => {
  const nr_regex = /\D+/g;
  return String(str).replace(nr_regex, '');
};

export const FormatError = (errorMsg: any) => {
  if (errorMsg?.response?.data?.message) {
    return errorMsg.response.data.message.split('.');
  }
  if (errorMsg?.response?.status === 500) {
    return 'Ocorreu um erro, tente novamente mais tarde';
  }
  return errorMsg.split('.');
};

export const ViewErrorToast = (errors: any): Promise<any> => {
  return errors.forEach(
    (
      element:
        | boolean
        | React.ReactChild
        | React.ReactFragment
        | React.ReactPortal
        | ((props: ToastContentProps) => React.ReactNode)
        | null
        | undefined,
    ) => {
      toast.error(element);
    },
  );
};
