import * as React from 'react';
import { Route } from 'react-router-dom';
import Admin from '../pages/DashboardAdmin';
import Users from '../pages/DashboardAdmin/Users';
import Auctions from '../pages/DashboardAdmin/Auctions';
import AuctionRegister from '../pages/DashboardAdmin/AuctionRegister';
import AuctionDetail from '../pages/DashboardAdmin/AuctionUpdate';
import UserDetail from '../pages/DashboardAdmin/UserDetail';
import SignUp from '../pages/DashboardAdmin/SignUp';
import AppRouter from './app.routes';

const AdminRouter: React.FC = () => {
  return (
    <>
      <Route exact path="/dashboard/admin" component={Admin} />
      <Route exact path="/dashboard/admin/users" component={Users} />
      <Route
        exact
        path="/dashboard/admin/user/detail/:id_person"
        component={UserDetail}
      />
      <Route exact path="/dashboard/admin/auctions" component={Auctions} />
      <Route
        exact
        path="/dashboard/admin/auction/register"
        component={AuctionRegister}
      />
      <Route
        exact
        path="/dashboard/admin/auction/detail/:id_auction"
        component={AuctionDetail}
      />

      <Route exact path="/dashboard/admin/sign/up/user" component={SignUp} />

      <AppRouter />
    </>
  );
};

export default AdminRouter;
