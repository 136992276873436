/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Iparam, IUser, IUserPassword } from '../../providers/use-user';
import { api } from './config';

export class User {
  async index<T>(params: Iparam): Promise<T> {
    return api.get(`/user`, { params });
  }

  async indexSearchParams<T>(params: Iparam): Promise<T> {
    return api.get(`/user/search/params`, { params });
  }

  async get<T>(id_person: any, id_company: any): Promise<T> {
    return api.get(`/user/show/${id_company}/${id_person}`);
  }

  async getAddress<T>(id_person: any): Promise<T> {
    return api.get(`/address/show/${id_person}`);
  }

  async update<T>(data: IUser): Promise<T> {
    return api.put(`/user/update/${data?.id_person}`, data);
  }

  async updatePassword<T>(data: IUserPassword): Promise<T> {
    return api.put(`/user/update/passowrd/${data?.id_person}`, data);
  }
}
