/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ChangeEvent, useMemo, useState } from 'react';
import { Button, CssBaseline, Grid, TextField } from '@material-ui/core';

import { styled } from '@material-ui/styles';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DrawerAdmin from '../../../components/DrawerAdmin';

import Footer from '../../../components/Footer';
import hammerIcon from '../../../assets/images/icons/martelo.png';
import cowBannerExemple from '../../../assets/images/banner_exemple.png';

import { useSignInContext } from '../../../providers/use-signin';

import useStyles from '../../../assets/styles/pages/admin/auctions';
import { api } from '../../../services';
import {
  FormatError,
  FormatHoursInput,
  ViewErrorToast,
} from '../../../helpers/formatting';
import { auctionRegister } from '../../../helpers/isValidData/auction';
import Loading from '../../../components/Loading';

const AuctionRegiter: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [btnDisable, setBtnDisable] = useState<boolean>(false);
  const classeStyle = useStyles();
  const useSignIn = useSignInContext();
  const history = useHistory();

  const [dataAuction, setDataAuction] = useState<any>({
    id_person: useSignIn.data.user.id_person,
  });
  const [imgBanner, setImgBanner] = useState<any>(null);
  // const [video, setVideo] = useState<any>(null);
  const [catalog, setCatalog] = useState<any>(null);
  const [entryOrder, setEntryOrder] = useState<any>(null);

  const previewImgPorfile: any = useMemo(() => {
    return imgBanner ? URL.createObjectURL(imgBanner) : null;
  }, [imgBanner]);

  const handleChangeFormImg = (event: any) => {
    setImgBanner(event.target.files[0]);
  };

  /*  const handleChangeFormVideo = (event: any) => {
    setVideo(event.target.files[0]);
  }; */

  const handleChangeFormCatalog = (event: any) => {
    setCatalog(event.target.files[0]);
  };

  const handleChangeFormEntryOrder = (event: any) => {
    setEntryOrder(event.target.files[0]);
  };

  const handleChangeForm = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setDataAuction((field: any) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setBtnDisable(true);

    const errors = await auctionRegister(dataAuction, imgBanner);

    if (errors.length > 0) {
      setBtnDisable(false);
      await ViewErrorToast(FormatError(errors));
      return;
    }
    setLoading(true);
    try {
      const result: any = await api.apiLs.auction.create(dataAuction);
      if (result.data.message?.id_auction) {
        const dataBanner = new FormData();

        dataBanner.append('des_banner', imgBanner);
        dataBanner.append('id_auction', String(result.data.message.id_auction));
        await api.apiLs.auction.createBanner(
          dataBanner,
          result.data.message.id_auction,
          useSignIn.data.user.id_person,
        );

        /* const dataVideo = new FormData();

        dataVideo.append('des_video', video);
        dataVideo.append('id_auction', String(result.data.message.id_auction));
        await api.apiLs.auction.createVideo(
          dataVideo,
          result.data.message.id_auction,
          useSignIn.data.user.id_person,
        ); */

        if (catalog?.name) {
          const dataCatalog = new FormData();

          dataCatalog.append('des_catalog', catalog);
          dataCatalog.append(
            'id_auction',
            String(result.data.message.id_auction),
          );
          await api.apiLs.auction.createCatalog(
            dataCatalog,
            result.data.message.id_auction,
            useSignIn.data.user.id_person,
          );
        }

        if (entryOrder?.name) {
          const dataEntryOrder = new FormData();

          dataEntryOrder.append('des_entry_order', entryOrder);
          dataEntryOrder.append(
            'id_auction',
            String(result.data.message.id_auction),
          );
          await api.apiLs.auction.createEntryOrder(
            dataEntryOrder,
            result.data.message.id_auction,
            useSignIn.data.user.id_person,
          );
        }
        setLoading(false);
        setBtnDisable(false);
        toast.success('Leilão criado com sucesso');
        history.push('/dashboard/admin/auctions');
      }
    } catch (error: any) {
      setBtnDisable(false);
      setLoading(false);
      toast.error(error);
    }
  };

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <>
      {loading && <Loading />}
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAdmin />
        <main className={classeStyle.content}>
          <div className={classeStyle.toolbar} />
          <div className={classeStyle.paper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                className={classeStyle.hammerIcon}
                src={hammerIcon}
                alt="Leilões"
              />
              <h1 className={classeStyle.hammerIconTitle}>
                Cadastro de leilão
              </h1>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <form onSubmit={handleSubmit} className={classeStyle.form}>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  spacing={2}
                  style={{ padding: 10 }}
                >
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_title"
                      label="Título:"
                      name="des_title"
                      autoComplete="title"
                      value={dataAuction?.des_title}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      rows={5}
                      multiline
                      variant="outlined"
                      required
                      fullWidth
                      id="des_description"
                      label="Descrição:"
                      name="des_description"
                      autoComplete="des_description"
                      value={dataAuction?.description}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      type="date"
                      required
                      fullWidth
                      id="dt_start"
                      label="Data:"
                      name="dt_start"
                      autoComplete="dt_start"
                      value={dataAuction?.dt_start}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="hr_start"
                      label="Início:"
                      name="hr_start"
                      autoComplete="hr_start"
                      value={FormatHoursInput(dataAuction?.hr_start)}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="hr_end"
                      label="Fim:"
                      name="hr_end"
                      value={FormatHoursInput(dataAuction?.hr_end)}
                      autoComplete="hr_end"
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="qt_lots"
                      label="Quantidade de lotes:"
                      name="qt_lots"
                      autoComplete="qt_lots"
                      value={dataAuction?.qt_lots}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="qt_installments"
                      label="Quantidade de parcelas:"
                      name="qt_installments"
                      autoComplete="qt_installments"
                      value={dataAuction?.qt_installments}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_races"
                      label="Raça(s):"
                      name="des_races"
                      autoComplete="des_races"
                      value={dataAuction?.des_races}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_auctioneer"
                      label="Leiloeira:"
                      name="des_auctioneer"
                      autoComplete="des_auctioneer"
                      value={dataAuction?.des_auctionner}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_location"
                      label="Localização:"
                      name="des_location"
                      autoComplete="des_location"
                      value={dataAuction?.des_location}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_organizer"
                      label="Organizador:"
                      name="des_organizer"
                      autoComplete="des_organizer"
                      value={dataAuction?.des_organizer}
                      onChange={handleChangeForm}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  spacing={2}
                  style={{ padding: 10 }}
                >
                  <Grid item sm={12}>
                    <img
                      className={classeStyle.ImgBanner}
                      src={previewImgPorfile || cowBannerExemple}
                      alt="Leilões"
                    />
                    <Grid container>
                      <Grid item xs={12}>
                        <label htmlFor="contained-button-file-img">
                          <Input
                            onChange={handleChangeFormImg}
                            accept="image/*"
                            id="contained-button-file-img"
                            multiple
                            type="file"
                          />
                          <Button
                            variant="contained"
                            component="span"
                            className={classeStyle.btnUpload}
                          >
                            Adicionar banner *
                          </Button>
                        </label>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <label htmlFor="contained-button-file-video">
                          <Input
                            accept="video/*"
                            onChange={handleChangeFormVideo}
                            id="contained-button-file-video"
                            multiple
                            type="file"
                          />
                           <Button
                            variant="contained"
                            className={classeStyle.btnUpload}
                            component="span"
                            style={
                              video?.name ? { backgroundColor: '#140dda' } : {}
                            }
                          >
                            Adicionar vídeo *
                          </Button>
                        </label>
                      </Grid> */}
                      <Grid item xs={12}>
                        <label htmlFor="contained-button-file-catalog">
                          <Input
                            accept="application/pdf"
                            onChange={handleChangeFormCatalog}
                            id="contained-button-file-catalog"
                            multiple
                            type="file"
                          />
                          <Button
                            variant="contained"
                            className={classeStyle.btnUpload}
                            component="span"
                            style={
                              catalog?.name
                                ? { backgroundColor: '#140dda' }
                                : {}
                            }
                          >
                            Adicionar Catalogo
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={12}>
                        <label htmlFor="contained-button-file-entry-order">
                          <Input
                            accept="application/pdf"
                            onChange={handleChangeFormEntryOrder}
                            id="contained-button-file-entry-order"
                            multiple
                            type="file"
                          />
                          <Button
                            variant="contained"
                            className={classeStyle.btnUpload}
                            component="span"
                            style={
                              entryOrder?.name
                                ? { backgroundColor: '#140dda' }
                                : {}
                            }
                          >
                            Adicionar Ordem de entrada
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <button
                  className={classeStyle.btnRegister}
                  disabled={btnDisable}
                  type="submit"
                >
                  Publicar
                </button>
              </form>
            </Grid>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default AuctionRegiter;
