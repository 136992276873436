/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { CssBaseline, Typography, Container } from '@material-ui/core';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const TermsPrivacyPolicy: React.FC = () => {
  return (
    <>
      <Header />
      <Container component="main" maxWidth="md" id="sign-in">
        <CssBaseline />
        <div
          style={{
            display: 'flex',
            margin: '60px 0 20px 0',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h5">
            Termos e políticas de privacidade
          </Typography>
          <Typography component="h1" variant="h5">
            Soma Web
          </Typography>
        </div>
        <p
          style={{
            margin: '0px 0 60px 0',
          }}
        >
          Política de Privacidade Soma Web disponibiliza seu aplicativo de forma
          gratuita, Este SERVIÇO é fornecido pela Soma Web sem custo e
          destina-se a ser utilizado como está. <br /> Esta página é usada para
          informar os visitantes sobre nossas políticas com a coleta, uso e
          divulgação de Informações Pessoais, se alguém decidir usar o nosso
          Serviço. <br /> Se você optar por usar nosso Serviço, você concorda
          com a coleta e uso de informações em relação a esta política. As
          Informações Pessoais que coletamos são usadas para fornecer e melhorar
          o serviço. Não usaremos ou compartilharemos suas informações com
          ninguém, exceto conforme descrito nesta Política de Privacidade.{' '}
          <br /> Os termos usados nesta Política de Privacidade têm os mesmos
          significados que em nossos Termos e Condições, que é acessível na Soma
          Web, a menos que definido de outra forma nesta Política de
          Privacidade. <br /> <b>Coleta e uso de informações </b> <br /> Para
          uma melhor experiência, ao usar nosso Serviço, podemos exigir que você
          nos forneça informações pessoalmente identificáveis, incluindo, mas
          não se limitando a, Nome, Email, Telefone, Documento, o Firebase
          Device Id será associado ao seu ID de usuário em nosso site, que será
          usado apenas para o envio de notificações segmentadas, de acordo com
          as configurações de cada usuário. As informações que solicitamos serão
          retidas por nós e usadas conforme descrito nesta política de
          privacidade. <br /> O aplicativo usa serviços de terceiros que podem
          coletar informações usadas para identificá-lo. <br />{' '}
          <b>Log de Dados </b> <br /> Queremos informá-lo que sempre que você
          usar o nosso serviço, em caso de erro no aplicativo, coletamos dados e
          informações (através de produtos de terceiros) em seu telefone chamado
          Log Data. Esses dados de registro podem incluir informações como o
          endereço IP do dispositivo, o nome do dispositivo, a versão do sistema
          operacional, a configuração do aplicativo ao utilizar nosso serviço, a
          hora e a data do seu uso do serviço e outras estatísticas. <br />{' '}
          <b>Cookies</b> <br /> Cookies são arquivos com uma pequena quantidade
          de dados que são comumente usados como identificadores exclusivos
          anônimos. Estes são enviados para o seu navegador a partir dos sites
          que você visita e são armazenados na memória interna do seu
          dispositivo. memória. <br /> Este serviço não usa esses “cookies”
          explicitamente. No entanto, o aplicativo pode usar código de terceiros
          e bibliotecas que usam “cookies” para coletar informações e melhorar
          seus serviços. Você tem a opção de aceite ou recuse esses cookies e
          saiba quando um cookie está sendo enviado para o seu dispositivo. Se
          você escolher para recusar nossos cookies, você pode não conseguir
          usar algumas partes deste Serviço. <br />{' '}
          <b>Provedores de Serviços</b> <br /> Podemos empregar empresas e
          indivíduos de terceiros devido aos seguintes motivos: <br />
          <br />
          • Para facilitar nosso serviço; <br />
          • Para fornecer o serviço em nosso nome; <br />
          • Para executar serviços relacionados a serviços; ou <br />
          •Para nos ajudar a analisar como nosso Serviço é usado. <br />
          <br />
          Queremos informar aos usuários deste Serviço que esses terceiros têm
          acesso a suas informações pessoais. O motivo é executar as tarefas
          atribuídas a eles em nosso nome. Contudo, eles são obrigados a não
          divulgar ou usar as informações para qualquer outra finalidade. <br />
          <b>Segurança</b> <br /> Valorizamos sua confiança em nos fornecer suas
          informações pessoais, por isso estamos nos esforçando usar meios
          comercialmente aceitáveis de protegê-lo. Mas lembre-se que nenhum
          método de transmissão a internet, ou o método de armazenamento
          eletrônico é 100% seguro e confiável, e não podemos garantir sua
          segurança absoluta. <br /> <b>Links para Outros Site</b> <br />
          Este Serviço pode conter links para outros sites. Se você clicar em um
          link de terceiros, você será direcionado para esse site. Observe que
          esses sites externos não são operados por nós. Portanto, nós
          fortemente aconselhá-lo a rever a Política de Privacidade desses
          sites. Nós não temos controle sobre e não assumimos qualquer
          responsabilidade pelo conteúdo, políticas de privacidade ou práticas
          de quaisquer sites de terceiros ou serviços. <br />{' '}
          <b>Privacidade Infantil</b> <br />
          Estes Serviços não abordam ninguém com idade inferior a 13 anos.
          informações pessoalmente identificáveis de crianças menores de 13
          anos. No caso, descobrimos que uma criança abaixo de 13 nos forneceu
          informações pessoais, nós imediatamente excluímos isso de nossos
          servidores. Se você é pai ou responsável e sabe que seu filho nos
          forneceu informações pessoais informações, entre em contato conosco
          para que possamos realizar as ações necessárias. <br />{' '}
          <b>Alterações desta Política de Privacidade </b> <br /> Podemos
          atualizar nossa Política de Privacidade de tempos em tempos. Assim,
          você é aconselhado a revisar esta página periodicamente para quaisquer
          alterações. Vamos notificá-lo de quaisquer alterações por postar a
          nova Política de Privacidade nesta página. Estas alterações entram em
          vigor imediatamente após serem publicadas em esta página. <br />{' '}
          <b>Contact Us </b> <br /> Se você tiver dúvidas ou sugestões sobre
          nossa Política de Privacidade, não hesite em entrar em contato.
        </p>
      </Container>
      <Footer />
    </>
  );
};

export default TermsPrivacyPolicy;
