/* eslint-disable @typescript-eslint/no-explicit-any */
import { Auth } from './apiLs/auth';
import { User } from './apiLs/user';
import { Company } from './apiLs/company';
import { Address } from './apiLs/address';
import { Auction } from './apiLs/auction';

export interface HttpRequestI {
  error: boolean;
  loading: boolean;
  success: boolean;
  loadingBar: any;
  errorMsg: '';
}

class Api {
  readonly apiLs = {
    auth: new Auth(),
    user: new User(),
    company: new Company(),
    address: new Address(),
    auction: new Auction(),
  };
}

export const api = new Api();
