/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CssBaseline, Grid, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { useHistory } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import {
  actionListUser,
  IListUser,
} from '../../../store/reduces/ReducerListUser';
import { RootState } from '../../../store';

import DrawerAdmin from '../../../components/DrawerAdmin';

import Footer from '../../../components/Footer';

import useStyles from '../../../assets/styles/pages/admin/users';
import { FormatPhone } from '../../../helpers/formatting';

const Users: React.FC = () => {
  const history = useHistory();
  const classeStyle = useStyles();
  const { reducerListUser } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    des_search: '',
    des_type_user: '',
  });

  useEffect(() => {
    dispatch(actionListUser.searchListUser({ filter: params }));
  }, [params]);

  const handlePage = (url: string) => {
    history.push(url);
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    if (params.page != value) {
      setParams((field) => {
        return {
          ...field,
          page: value,
        };
      });
    }
  };

  const handleChangeForm = (event: ChangeEvent<any>) => {
    const { name } = event.target;
    const { value } = event.target;

    setParams((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };
  return (
    <>
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAdmin />
        <main className={classeStyle.content}>
          <div className={classeStyle.toolbar} />
          <div className={classeStyle.paper}>
            <p className={classeStyle.filterText}>Filtro</p>

            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={4}>
                <TextField
                  autoComplete="fname"
                  name="des_search"
                  variant="outlined"
                  required
                  fullWidth
                  id="des_search"
                  label="Procurar"
                  autoFocus
                  onChange={handleChangeForm}
                />
              </Grid>
              <Grid
                container
                item
                xs={5}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  type="button"
                  variant="contained"
                  className={classeStyle.btnEdit}
                  onClick={() => handlePage(`/dashboard/admin/sign/up/user`)}
                >
                  + Cadastrar usuário
                </Button>
              </Grid>
            </Grid>
            {reducerListUser.data && (
              <>
                <TableContainer component={Paper}>
                  <Table
                    className={classeStyle.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">Telefone</TableCell>
                        <TableCell align="center">E-mail</TableCell>
                        <TableCell align="center">&nbsp;</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reducerListUser.data.map((row: IListUser) => (
                        <TableRow key={row.id_person}>
                          <TableCell component="th" scope="row">
                            {row.id_person}
                          </TableCell>
                          <TableCell align="center">{row.des_person}</TableCell>
                          <TableCell align="center">
                            {FormatPhone(row.nr_phone)}
                          </TableCell>
                          <TableCell align="center">
                            {row.account.des_email}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              type="button"
                              variant="outlined"
                              color="primary"
                              className={classeStyle.btnEdit}
                              onClick={() =>
                                handlePage(
                                  `/dashboard/admin/user/detail/${row.id_person}`,
                                )
                              }
                            >
                              Editar
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  className={classeStyle.pagination}
                  page={params.page}
                  count={Math.ceil(reducerListUser.headers?.xtotalcount / 10)}
                  onChange={handleChangePage}
                />
              </>
            )}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Users;
