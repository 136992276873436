import { configureStore } from '@reduxjs/toolkit';
import { reducers } from './reduces';

export type RootState = ReturnType<typeof reducers>;

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
