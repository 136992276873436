/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { CssBaseline, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from '../../assets/styles/pages/admin';

import DrawerAuction from '../../components/DrawerAuction';

import framIcon from '../../assets/images/icons/fazenda.png';
import hammerIcon from '../../assets/images/icons/martelo.png';
import blogIcon from '../../assets/images/icons/megafone.png';
import userIcon from '../../assets/images/icons/usuario.png';

const Home: React.FC = () => {
  const classeStyle = useStyles();
  const history = useHistory();

  const handleToPage = (url: string) => {
    history.push(url);
  };

  return (
    <div className={classeStyle.root}>
      <CssBaseline />
      <DrawerAuction />
      <main className={classeStyle.content}>
        <div className={classeStyle.toolbar} />
        <Grid
          item
          sm={12}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sm={8}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              className={classeStyle.button}
              item
              xs={12}
              sm={12}
              md={5}
              onClick={() => handleToPage('/dashboard/users')}
            >
              <div className={classeStyle.LinkA}>
                <img src={userIcon} alt="Usuário" />
                <h1 className={classeStyle.buttonText}>Usuários</h1>
              </div>
            </Grid>
            <Grid
              className={classeStyle.button}
              item
              xs={12}
              sm={12}
              md={5}
              onClick={() => handleToPage('/dashboard/auctions')}
            >
              <div className={classeStyle.LinkA}>
                <img src={hammerIcon} alt="Leilões" />
                <h1 className={classeStyle.buttonText}>Leilões</h1>
              </div>
            </Grid>
            <Grid className={classeStyle.button} item xs={12} sm={12} md={5}>
              <div className={classeStyle.LinkA}>
                <img src={framIcon} alt="Fazendas" />
                <h1 className={classeStyle.buttonText}>Fazendas</h1>
              </div>
            </Grid>
            <Grid className={classeStyle.button} item xs={12} sm={12} md={5}>
              <a
                href="https://www.leiloae.com.br/"
                target="_blank"
                rel="noopener noreferrer"
                className={classeStyle.LinkA}
              >
                <img src={blogIcon} alt="Blog" />
                <h1 className={classeStyle.buttonText}>Blog</h1>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};

export default Home;
