import { createStyles, makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#f0f0f0',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0, 1),
    },
    paper: {
      height: '130vh',
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.between('sm', 'md')]: {},
      '@media (min-width: 1280px)': {},
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: '#fff',
      padding: 30,
      borderRadius: 30,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    signIn: {
      marginTop: 10,
    },
    btnGreen: {
      marginTop: 20,
    },
  }),
);

export default useStyles;
