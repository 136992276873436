/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Container, Typography } from '@material-ui/core';
import useStyles from '../../assets/styles/components/footer';
import { useSignInContext } from '../../providers/use-signin';

const Copyright: React.FC = () => {
  return (
    <Typography align="center" variant="body2" color="textSecondary">
      {'Copyright © '} Desenvolvido por{' '}
      <Link color="inherit" href="#">
        Ê-Sistemas -
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
};

const Footer: React.FC = () => {
  const classeStyle = useStyles();
  const useSignIn = useSignInContext();
  return (
    <>
      <footer className={classeStyle.footer}>
        <Container maxWidth="sm">
          <Typography variant="body1">
            {useSignIn.data.user?.company?.des_fantasy_name}
          </Typography>
          <Copyright />
        </Container>
      </footer>
    </>
  );
};

export default Footer;
