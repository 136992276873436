/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-children-prop */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { toast } from 'react-toastify';

import useStyles from '../../../assets/styles/pages/admin/users';
import { IAddress, useAddressContext } from '../../../providers/use-address';
import {
  FormatCep,
  FormatError,
  ViewErrorToast,
} from '../../../helpers/formatting';
import { addressUpdate } from '../../../helpers/isValidData/user-update';
import { useSignInContext } from '../../../providers/use-signin';

const Address: React.FC = () => {
  const classeStyle = useStyles();
  const addressContext = useAddressContext();
  const signInContext = useSignInContext();

  const [addressDataUpdate, setAddressDataUpdate] = useState<IAddress>({
    id_company: 0,
    id_address: 0,
    nr_zip_code: '',
    des_address: '',
    des_number: '',
    des_district: '',
    des_reference: '',
    nr_city: 0,
    nr_state: 0,
  });

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const errors = await addressUpdate(addressDataUpdate);

    if (errors.length > 0) {
      await ViewErrorToast(FormatError(errors));
      return;
    }

    if (addressDataUpdate.id_address) {
      await addressContext.update(addressDataUpdate);
      return;
    }

    await addressContext.create(addressDataUpdate);
  };

  const handleChangeForm = (
    event: ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const { name } = event.target;
    const { value } = event.target;

    setAddressDataUpdate((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  const handleChangeFormStateAndCity = (name: string, value: string) => {
    setAddressDataUpdate((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  useEffect(() => {
    if (addressContext.data.msgError) {
      toast.error(addressContext.data.msgError);
    }
  }, [addressContext.data.msgError]);

  useEffect(() => {
    if (addressContext.data.msgSuccess) {
      toast.success(addressContext.data.msgSuccess);
    }
  }, [addressContext.data]);

  useEffect(() => {
    addressContext.indexState();
  }, []);

  useEffect(() => {
    if (addressDataUpdate.nr_state)
      addressContext.indexSearchCity(addressDataUpdate.nr_state);
  }, [addressDataUpdate.nr_state]);

  useEffect(() => {
    setAddressDataUpdate({
      id_company: signInContext?.data?.user?.id_company,
      id_address: addressContext?.data?.address?.id_address,
      nr_zip_code: addressContext.data.address?.nr_zip_code,
      des_address: addressContext.data.address?.des_address,
      des_number: addressContext.data.address?.des_number,
      des_district: addressContext.data.address?.des_district,
      des_reference: addressContext.data.address?.des_reference,
      nr_city: addressContext.data.address?.nr_city,
      nr_state: addressContext.data.address?.nr_state,
    });
  }, [addressContext.data.address, signInContext?.data?.user]);

  return (
    <>
      <Container component="main" maxWidth="xs" id="sign-up">
        <div>
          <Typography component="h1" variant="h5">
            {addressDataUpdate.id_address
              ? 'Atualizar endereço'
              : 'Cadastrar endereço'}
          </Typography>
          <form onSubmit={handleSubmit} className={classeStyle.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="nr_zip_code"
                  label="CEP"
                  name="nr_zip_code"
                  onChange={handleChangeForm}
                  value={FormatCep(addressDataUpdate.nr_zip_code)}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  name="des_address"
                  variant="outlined"
                  required
                  fullWidth
                  id="des_address"
                  label="AV/RUA"
                  autoFocus
                  onChange={handleChangeForm}
                  value={addressDataUpdate.des_address}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="des_number"
                  label="Número"
                  name="des_number"
                  onChange={handleChangeForm}
                  value={addressDataUpdate.des_number}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="des_district"
                  label="Bairro"
                  name="des_district"
                  onChange={handleChangeForm}
                  value={addressDataUpdate.des_district}
                />
              </Grid>
              <Grid item xs={12}>
                {addressContext.dataState?.state && (
                  <Autocomplete
                    id="state"
                    options={addressContext.dataState?.state}
                    getOptionLabel={(option: any) => option.des_uf}
                    value={addressContext?.dataState?.state.find(
                      (data: { id_uf: number }) => {
                        return data.id_uf === addressDataUpdate.nr_state;
                      },
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        value={addressDataUpdate.nr_state}
                        variant="outlined"
                        name="nr_state"
                        fullWidth
                        required
                      />
                    )}
                    onChange={(event, value) => {
                      handleChangeFormStateAndCity('nr_state', value?.id_uf);
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                {addressContext.dataCity?.city && (
                  <Autocomplete
                    id="city"
                    options={addressContext.dataCity?.city}
                    getOptionLabel={(option: any) => option.des_city}
                    value={addressContext?.dataCity?.city.find(
                      (data: { code_city: number }) => {
                        return data.code_city === addressDataUpdate.nr_city;
                      },
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cidade"
                        value={addressDataUpdate.nr_city}
                        variant="outlined"
                        name="nr_city"
                        fullWidth
                        required
                      />
                    )}
                    onChange={(event, value) => {
                      handleChangeFormStateAndCity('nr_city', value?.code_city);
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={`${classeStyle.submit}`}
            >
              {addressDataUpdate.id_address
                ? 'Atualizar endereço'
                : 'Cadastrar endereço'}
            </Button>
          </form>
        </div>
      </Container>
    </>
  );
};

export default Address;
