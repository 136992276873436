/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSignInContext } from '../../providers/use-signin';

import useStyles from '../../assets/styles/pages/signin';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const SignIn: React.FC = () => {
  const classeStyle = useStyles();
  const [formSign, setFormSign] = useState({
    des_username: '',
    des_password: '',
  });
  const history = useHistory();
  const { signing, data } = useSignInContext();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!formSign.des_username) {
      return toast.error('Informe seu e-mail ou telefone');
    }
    if (!formSign.des_password) {
      return toast.error('Informe sua senha');
    }
    await signing(formSign);
  };

  useEffect(() => {
    if (data?.msgError) {
      toast.error(data?.msgError);
    }

    if (data?.user?.account?.des_type_user[0] === 'inadmin') {
      history.push('/dashboard/admin');
      return;
    }
    if (
      data?.user?.account?.des_type_user[0] === 'admemployee' ||
      data?.user?.account?.des_type_user[0] === 'employee'
    ) {
      history.push('/dashboard');
      return;
    }

    if (
      data?.user?.account?.des_type_user[0] === 'inban' ||
      data?.user?.account?.des_type_user[0] === 'indisabled'
    ) {
      toast.error(
        'Ocorreu um erro ao acessar sua conta, entre em contato para ajudarmos a resolver',
      );
    }
  }, [data]);

  const handleChangeForm = (event: any) => {
    const { name } = event.target;
    const { value } = event.target;

    setFormSign((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };
  return (
    <>
      <Header />
      <Container component="main" maxWidth="xs" id="sign-in">
        <CssBaseline />
        <div className={classeStyle.paper}>
          <Avatar className={classeStyle.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Acessar painel
          </Typography>
          <form onSubmit={handleSubmit} className={classeStyle.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="des_username"
              label="E-mail ou telefone"
              name="des_username"
              autoComplete="des_username"
              autoFocus
              onChange={handleChangeForm}
              value={formSign.des_username}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="des_password"
              label="Senha"
              type="password"
              id="des_password"
              autoComplete="current-password"
              onChange={handleChangeForm}
              value={formSign.des_password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="default"
              className={classeStyle.submit}
            >
              Entrar
            </Button>
            <Grid>
              <Grid
                item
                xs
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classeStyle.forgotPassword}
              >
                <Link href="/forgot/password" variant="body2">
                  Recuperar senha
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default SignIn;
