/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

import { useSignInContext } from '../providers/use-signin';
import { useLoadingContext } from '../providers/use-loading';

import RouterAuth from './auth.routes';
import RouterAdmin from './ dashboard.admin.routes';
import RouterAuction from './ dashboard.auction.routes';

import Loading from '../components/Loading';

const Routes: React.FC = () => {
  const { data, signed } = useSignInContext();
  const loading = useLoadingContext();

  if (loading.data.loading) {
    return <Loading />;
  }

  if (!signed) {
    return (
      <BrowserRouter>
        <Switch>
          <RouterAuth />
        </Switch>
      </BrowserRouter>
    );
  }
  if (data.user?.account?.des_type_user[0] === 'inadmin') {
    return (
      <BrowserRouter>
        <Switch>
          <RouterAdmin />
        </Switch>
      </BrowserRouter>
    );
  }
  if (
    data.user?.account?.des_type_user[0] === 'admemployee' ||
    data.user?.account?.des_type_user[0] === 'employee'
  ) {
    return (
      <BrowserRouter>
        <Switch>
          <RouterAuction />
        </Switch>
      </BrowserRouter>
    );
  }
  if (data.user?.account?.des_type_user[0] === 'inclient') {
    return (
      <BrowserRouter>
        <Switch>
          <RouterAuth />
        </Switch>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Switch>
        <RouterAuth />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
