/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useTheme,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';

import { useHistory } from 'react-router-dom';
import { useSignInContext } from '../../providers/use-signin';

import useStyles from '../../assets/styles/components/draweradmin';
import exempleLogo from '../../assets/images/exemple_logo.jpeg';

interface Props {
  window?: () => Window;
}

const DrawerAdmin: React.FC<Props> = (props: Props) => {
  const classeStyle = useStyles();
  const { window } = props;
  const theme = useTheme();
  const { signingOut, data } = useSignInContext();
  const history = useHistory();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    return null;
  };

  const handleLogout = () => {
    signingOut();
    history.push('/');
  };

  const handleToPage = (url: string) => {
    history.push(url);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <div>
      <h1 className={classeStyle.namePanel}>Painel gerencial</h1>
      <Divider />
      <div className={classeStyle.dataPersonAvatar}>
        <img
          className={classeStyle.avatarImg}
          src={data.user?.company.PhotoLogo?.url || exempleLogo}
          alt="avatar"
        />
        <h1 style={{ textAlign: 'center' }}>{data.user?.des_person}</h1>
        <Button style={{ color: '#7b39e3' }} onClick={() => handleLogout()}>
          Sair da conta
        </Button>
      </div>
      <List className={classeStyle.menuList}>
        <ListItem button onClick={() => handleToPage('/dashboard')}>
          <ListItemText primary="Inicio" />
        </ListItem>
        <ListItem button onClick={() => handleToPage('/dashboard/auctions')}>
          <ListItemText primary="Leilões" />
        </ListItem>
        <ListItem button onClick={() => handleToPage('/dashboard/company')}>
          <ListItemText primary="Empresa" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Notícias" />
        </ListItem>
        <ListItem button>
          <ListItemText primary="Fazendas" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" className={classeStyle.appBar}>
        <Grid container>
          <Grid item xs>
            <Toolbar>
              <IconButton
                color="default"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classeStyle.menuButton}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
      <nav className={classeStyle.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classeStyle.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classeStyle.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </>
  );
};
export default DrawerAdmin;
