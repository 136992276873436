/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-children-prop */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import useStyles from '../../../assets/styles/pages/admin/users';
import { useSignInContext } from '../../../providers/use-signin';
import { useUserContext, IUser } from '../../../providers/use-user';
import {
  FormatCpf,
  FormatError,
  FormatPhone,
  ViewErrorToast,
} from '../../../helpers/formatting';
import { userUpdate } from '../../../helpers/isValidData/user-update';

const DataUpdate: React.FC = () => {
  const classeStyle = useStyles();
  const signInContext = useSignInContext();
  const userContext = useUserContext();
  const [dataUpdate, setDataUpdate] = useState<IUser>({
    id_person: userContext.data?.user?.id_person,
    des_person: userContext.data?.user?.des_person,
    des_lest_person: userContext.data?.user?.des_lest_person,
    nr_phone: userContext.data?.user?.nr_phone,
    des_email: userContext.data?.user?.account?.des_email,
    des_password: userContext.data?.user?.des_password,
    nr_cpf: userContext.data?.user?.nr_cpf,
    des_type_user: userContext.data?.user?.account.des_type_user[0],
  });

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const errors = await userUpdate(dataUpdate);

    if (errors.length > 0) {
      await ViewErrorToast(FormatError(errors));
      return;
    }

    await userContext.update(dataUpdate);

    await userContext.get(
      signInContext.data?.user?.id_person,
      signInContext.data?.user?.id_company,
    );
  };

  const handleChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;
    setDataUpdate((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  useEffect(() => {
    if (userContext.data?.msgError) {
      toast.error(userContext.data?.msgError);
    }
  }, [userContext?.data?.msgError]);

  useEffect(() => {
    if (userContext.data?.msgSuccess) {
      toast.success(userContext.data?.msgSuccess);
    }
  }, [userContext.data?.msgSuccess]);

  return (
    <>
      <div className={classeStyle.root}>
        <Container component="main" maxWidth="xs" id="sign-up">
          <div>
            <Typography component="h1" variant="h5">
              Atualizar dados
            </Typography>
            {dataUpdate && (
              <form
                onSubmit={handleSubmit}
                className={classeStyle.form}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="fname"
                      name="des_person"
                      variant="outlined"
                      required
                      fullWidth
                      id="des_person"
                      label="Nome"
                      autoFocus
                      onChange={handleChangeForm}
                      value={dataUpdate.des_person}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_lest_person"
                      label="Sobre nome"
                      name="des_lest_person"
                      autoComplete="lname"
                      onChange={handleChangeForm}
                      value={dataUpdate.des_lest_person}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_email"
                      label="E-mail"
                      name="des_email"
                      autoComplete="email"
                      onChange={handleChangeForm}
                      value={dataUpdate.des_email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nr_phone"
                      label="Telefone / WhatsApp"
                      name="nr_phone"
                      autoComplete="phone"
                      onChange={handleChangeForm}
                      value={FormatPhone(
                        dataUpdate.nr_phone ? dataUpdate.nr_phone : 0,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nr_cpf"
                      label="CPF"
                      name="nr_cpf"
                      autoComplete="cpf"
                      onChange={handleChangeForm}
                      value={FormatCpf(dataUpdate.nr_cpf)}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={`${classeStyle.submit}`}
                >
                  Atualizar dados
                </Button>
              </form>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default DataUpdate;
