/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Container, Grid, Link } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

import useStyles from '../../assets/styles/components/header';
import { useSignInContext } from '../../providers/use-signin';

import LogoImg from '../../assets/images/logo_lsteste_white.png';

const Header: React.FC = ({ children }: any) => {
  const { signingOut } = useSignInContext();
  const history = useHistory();
  const classeStyle = useStyles();

  const handleLogout = () => {
    signingOut();
    history.push('/');
  };

  return (
    <header className={classeStyle.header}>
      <Container id="header">
        <Grid container direction="row">
          <Grid item xs={12} sm={8}>
            <div>
              <img
                className={classeStyle.logoImg}
                src={LogoImg}
                height="60px"
                alt="logo"
              />
            </div>
          </Grid>
          <Grid
            item
            xs
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {children === 1 ? (
              <Link className="btn btn-green" href="/">
                Acessar minha conta
              </Link>
            ) : children === 2 ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
              >
                Sair
              </Button>
            ) : (
              <Link className="btn btn-green" href="/sign/up/user">
                Criar um conta
              </Link>
            )}
          </Grid>
        </Grid>
      </Container>
    </header>
  );
};

export default Header;
