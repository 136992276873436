/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import { CssBaseline, Grid } from '@material-ui/core';

import DataPerson from './DataPerson';
import Address from './Address';
import Password from './Password';

import DrawerAdmin from '../../../components/DrawerAdmin';

import useStyles from '../../../assets/styles/pages/admin/users';
import { useUserContext } from '../../../providers/use-user';
import { useAddressContext } from '../../../providers/use-address';

const UserUpdate: React.FC = ({ match }: any) => {
  const { id_person } = match.params;

  const classeStyle = useStyles();
  const userContext = useUserContext();
  const addressContext = useAddressContext();

  const handleGetDataPersonAndAddress = async () => {
    await userContext.get(id_person);
    await addressContext.get(id_person);
  };

  useEffect(() => {
    handleGetDataPersonAndAddress();
  }, []);

  return (
    <>
      <div className={classeStyle.root}>
        <CssBaseline />
        <DrawerAdmin />
        <main className={classeStyle.content}>
          <div className={classeStyle.paper} style={{ marginTop: 80 }}>
            {userContext.data?.user?.id_person &&
              addressContext.data?.address?.id_address && (
                <Grid
                  item
                  container
                  spacing={3}
                  xs={12}
                  className={classeStyle.forms}
                >
                  <Grid item xs={12} md={6} lg={4}>
                    <DataPerson />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Address />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <Password />
                  </Grid>
                </Grid>
              )}
          </div>
        </main>
      </div>
    </>
  );
};

export default UserUpdate;
