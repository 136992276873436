/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { IAddress } from '../../providers/use-address';
import { ICompany } from '../../providers/use-company';
import { IUser, IUserPassword } from '../../providers/use-user';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const userUpdate = (data: IUser): any => {
  const erros: string[] = [];

  if (!data.id_person || data.id_person === null || data.id_person === 0) {
    erros.push('Ocorreu algum erro, tente novamente mais tarde');
  }

  if (!data.des_person || data.des_person === '') {
    erros.push('Informe seu nome');
  }

  if (!data.des_lest_person || data.des_lest_person === '') {
    erros.push('Informe seu sobre nome');
  }

  if (!data.nr_phone || data.nr_phone === '') {
    erros.push('Informe seu telefone');
  }

  if (!data.des_email || data.des_email === '') {
    erros.push('Informe seu e-mail');
  }
  return erros.join('.');
};

export const addressUpdate = (data: IAddress): any => {
  const erros: string[] = [];

  if (!data.nr_zip_code || data.nr_zip_code === '') {
    erros.push('Informe seu CEP');
  }

  if (!data.id_company || data.id_company === null) {
    erros.push('Ocorreu algum erro, tente novamente mais tarde');
  }

  if (!data.des_address || data.des_address === '') {
    erros.push('Informe nome da sua RUA/AV');
  }

  if (!data.des_number || data.des_number === '') {
    erros.push('Informe o número');
  }

  if (!data.des_district || data.des_district === '') {
    erros.push('Informe seu bairro');
  }

  if (!data.nr_city || data.nr_city === null || data.nr_city === 0) {
    erros.push('Informe sua cidade');
  }

  if (!data.nr_state || data.nr_state === null || data.nr_state === 0) {
    erros.push('Informe seu estado');
  }
  return erros.join('.');
};

export const passwordUpdate = (data: IUserPassword, typeUser?: string): any => {
  const erros: string[] = [];

  if (!data.id_person || data.id_person === null) {
    erros.push('Ocorreu algum erro, tente novamente mais tarde');
  }

  if (typeUser !== 'inadmin') {
    if (!data.des_password_old || data.des_password_old === '') {
      erros.push('Informe sua senha atual');
    }
    if (data.des_password_old === data.des_password_new) {
      erros.push('Sua senha nova tem que ser diferente da atual');
    }
  }

  if (!data.des_password_new || data.des_password_new === '') {
    erros.push('Informe sua nova senha');
  }

  if (!data.des_password_new_confirm || data.des_password_new_confirm === '') {
    erros.push('Informe sua confirmação da nova senha');
  }

  if (data.des_password_new !== data.des_password_new_confirm) {
    erros.push('A nova senha não conferem');
  }

  return erros.join('.');
};

export const companyUpdate = (data: ICompany): any => {
  const erros: string[] = [];

  if (!data.id_person || data.id_person === null || data.id_person === 0) {
    erros.push('Ocorreu algum erro, tente novamente mais tarde');
  }

  if (!data.des_social_name || data.des_social_name === '') {
    erros.push('Informe a razão social');
  }

  if (!data.des_fantasy_name || data.des_fantasy_name === '') {
    erros.push('Informe o nome fantasia');
  }

  if (!data.nr_phone || data.nr_phone === '') {
    erros.push('Informe seu telefone');
  }

  if (!data.nr_cnpj || data.nr_cnpj === '') {
    erros.push('Informe o CNPJ');
  }
  if (!data.url_site || data.url_site === '') {
    erros.push('Informe a URL do site');
  }
  return erros.join('.');
};
