import * as React from 'react';
import { Route } from 'react-router-dom';
import Admin from '../pages/DashboardAuction';
import Users from '../pages/DashboardAuction/Users';
import Company from '../pages/DashboardAuction/Company';
import Auctions from '../pages/DashboardAuction/Auctions';
import AuctionRegister from '../pages/DashboardAuction/AuctionRegister';
import AuctionDetail from '../pages/DashboardAuction/AuctionUpdate';
import UserDetail from '../pages/DashboardAuction/UserDetail';
import SignUp from '../pages/DashboardAuction/SignUp';
import AppRouter from './app.routes';

const DashboardRouter: React.FC = () => {
  return (
    <>
      <Route exact path="/dashboard" component={Admin} />
      <Route exact path="/dashboard/users" component={Users} />
      <Route
        exact
        path="/dashboard/user/detail/:id_person"
        component={UserDetail}
      />
      <Route exact path="/dashboard/company" component={Company} />
      <Route exact path="/dashboard/auctions" component={Auctions} />
      <Route
        exact
        path="/dashboard/auction/register"
        component={AuctionRegister}
      />
      <Route
        exact
        path="/dashboard/auction/detail/:id_auction"
        component={AuctionDetail}
      />

      <Route exact path="/dashboard/sign/up/user" component={SignUp} />

      <AppRouter />
    </>
  );
};

export default DashboardRouter;
