/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/no-children-prop */
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  Container,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import useStyles from '../../../assets/styles/pages/admin/users';
import { useSignInContext } from '../../../providers/use-signin';
import {
  FormatCpf,
  FormatError,
  FormatPhone,
  ViewErrorToast,
} from '../../../helpers/formatting';
import { companyUpdate } from '../../../helpers/isValidData/user-update';
import { ICompany, useCompanyContext } from '../../../providers/use-company';

const DataUpdate: React.FC = () => {
  const classeStyle = useStyles();
  const signInContext = useSignInContext();
  const companyContext = useCompanyContext();
  const [dataUpdate, setDataUpdate] = useState<ICompany>({
    id_person: 0,
    id_company: 0,
    des_fantasy_name: '',
    des_social_name: '',
    nr_cnpj: '',
    nr_state_registration: 0,
    nr_phone: '',
    url_site: '',
  });

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const errors = await companyUpdate(dataUpdate);

    if (errors.length > 0) {
      await ViewErrorToast(FormatError(errors));
      return;
    }

    await companyContext.update(dataUpdate);

    await companyContext.get(
      signInContext.data?.user?.id_company,
      signInContext.data?.user?.id_person,
    );
  };

  const handleChangeForm = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const { value } = event.target;
    setDataUpdate((field) => {
      return {
        ...field,
        [`${name}`]: value,
      };
    });
  };

  useEffect(() => {
    if (companyContext.data?.msgError) {
      toast.error(companyContext.data?.msgError);
    }
  }, [companyContext?.data?.msgError]);

  useEffect(() => {
    if (companyContext.data?.msgSuccess) {
      toast.success(companyContext.data?.msgSuccess);
    }
  }, [companyContext.data]);

  useEffect(() => {
    if (companyContext.data?.company) {
      setDataUpdate({
        id_person: signInContext.data?.user?.id_person,
        id_company: companyContext.data?.company?.id_company,
        des_fantasy_name: companyContext.data?.company?.des_fantasy_name,
        des_social_name: companyContext.data?.company?.des_social_name,
        nr_cnpj: companyContext.data?.company?.nr_cnpj,
        nr_state_registration:
          companyContext.data?.company?.nr_state_registration,
        nr_phone: companyContext.data?.company?.nr_phone,
        url_site: companyContext.data?.company?.url_site,
      });
    }
  }, [companyContext.data.company]);

  return (
    <>
      <div className={classeStyle.root}>
        <Container component="main" maxWidth="xs" id="sign-up">
          <div>
            <Typography component="h1" variant="h5">
              Atualizar dados
            </Typography>
            {dataUpdate && (
              <form
                onSubmit={handleSubmit}
                className={classeStyle.form}
                noValidate
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="des_social_name"
                      variant="outlined"
                      required
                      fullWidth
                      id="des_social_name"
                      label="Razão social"
                      autoFocus
                      onChange={handleChangeForm}
                      value={dataUpdate.des_social_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="des_fantasy_name"
                      label="Nome fantasia"
                      name="des_fantasy_name"
                      onChange={handleChangeForm}
                      value={dataUpdate.des_fantasy_name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nr_cnpj"
                      label="CNPJ"
                      name="nr_cnpj"
                      onChange={handleChangeForm}
                      value={dataUpdate.nr_cnpj}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nr_phone"
                      label="Telefone / WhatsApp"
                      name="nr_phone"
                      onChange={handleChangeForm}
                      value={FormatPhone(
                        dataUpdate.nr_phone ? dataUpdate.nr_phone : 0,
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="nr_state_registration"
                      label="Inscrição estadual"
                      name="nr_state_registration"
                      onChange={handleChangeForm}
                      value={FormatCpf(dataUpdate.nr_state_registration)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="url_site"
                      label="URL site"
                      name="url_site"
                      onChange={handleChangeForm}
                      value={dataUpdate.url_site}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={`${classeStyle.submit}`}
                >
                  Atualizar dados
                </Button>
              </form>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default DataUpdate;
