import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: 20,
      backgroundColor: '#01733f',
    },
    logoImg: {
      width: 'auto',
    },
  }),
);

export default useStyles;
