/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import React, { useContext, createContext, useState } from 'react';
import { api } from '../../services';
import { useLoadingContext } from '../use-loading';

interface iResult {
  status: number;
  data: {
    message: [] | any;
  };
}

export interface IparamSignIn {
  des_username?: string;
  des_password?: string;
}

export interface IUserResults {
  id_person: number;
  des_person: string;
  des_lest_person: string;
  nr_cpf: string;
  nr_rg: string;
  nr_phone: string;
  dt_birth: string;
  account: {
    id_account: number;
    des_email: string;
    des_type_user: [] | any;
    nr_policy_terms: number;
  };
}

export interface IUser {
  id_person?: number;
  des_person?: string;
  des_lest_person?: string;
  nr_phone?: string;
  nr_cpf?: string;
  des_email?: string;
  des_password?: string;
  des_type_user?: string;
  nr_policy_terms?: number;
}

type IData = {
  message?: {
    user?: IUserResults | any;
  };
  user?: IUserResults | any;
  signedLoading?: boolean;
  msgError?: string;
  msgSuccess?: string;
  statusCode?: number;
};

type ISignUpContext = {
  data: IData;
  dataSignIn: IparamSignIn;
  signup(params: IUser): Promise<void>;
};
const SignUpContext = createContext<Partial<ISignUpContext>>({});

export const UserSignUpProvider: React.FC = ({ children }: any) => {
  const { createloading } = useLoadingContext();

  const [data, setData] = useState<IData>({
    signedLoading: false,
    message: {},
    user: null,
    msgError: '',
    msgSuccess: '',
    statusCode: 0,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataSignIn, setDataSignIn] = useState<IparamSignIn>({
    des_username: '',
    des_password: '',
  });

  const signup = async (params: IUser | any) => {
    try {
      createloading(true);
      if (
        params.des_person == '' ||
        params.des_lest_person == '' ||
        params.nr_phone == '' ||
        params.nr_cpf == '' ||
        params.des_email == '' ||
        params.des_password == '' ||
        params.des_type_user == '' ||
        params.nr_policy_terms == '' ||
        params.nr_zip_code == '' ||
        params.des_address == '' ||
        params.des_number == '' ||
        params.des_district == '' ||
        params.nr_city == '' ||
        params.nr_state == ''
      ) {
        setData({
          msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
        });
        createloading(false);
        return;
      }

      const userData = await api.apiLs.auth.signUp<iResult>(params);

      setData({
        user: userData.data?.message,
        msgError: '',
        msgSuccess: 'Usuário cadastrado com sucesso',
      });
      createloading(false);
    } catch (error: any) {
      if (error?.response?.data?.message) {
        setData({
          msgError: error.response.data.message,
        });
        createloading(false);
        return;
      }
      setData({
        msgError: 'Ocorreu algum erro, tente novamente mais tarde.',
      });
      createloading(false);
    }
  };

  return (
    <SignUpContext.Provider
      value={{
        data,
        dataSignIn,
        signup,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignUpContext: any = () => {
  return useContext(SignUpContext);
};
