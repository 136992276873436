/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from './config';

export class Auction {
  async index<T>(): Promise<T> {
    return api.get(`/auction`);
  }

  async indexSearchParams<T>(params: any): Promise<T> {
    return api.get(`/auction`, { params });
  }

  async get<T>(id_auction: any): Promise<T> {
    return api.get(`/auction/show/${id_auction}`);
  }

  async create<T>(data: any): Promise<T> {
    return api.post(`/auction`, data);
  }

  async createLots<T>(id_auction: number, data: any): Promise<T> {
    return api.post(`/lot/auction/${id_auction}`, data);
  }

  async createBanner<T>(
    data: any,
    id_company: number,
    id_person: number,
  ): Promise<T> {
    return api.post(`/banner/auction/${id_company}/${id_person}`, data);
  }

  async createCatalog<T>(
    data: any,
    id_company: number,
    id_person: number,
  ): Promise<T> {
    return api.post(`/catalog/auction/${id_company}/${id_person}`, data);
  }

  async createEntryOrder<T>(
    data: any,
    id_company: number,
    id_person: number,
  ): Promise<T> {
    return api.post(`/entryorder/auction/${id_company}/${id_person}`, data);
  }

  async createVideo<T>(
    data: any,
    id_company: number,
    id_person: number,
  ): Promise<T> {
    return api.post(`/video/auction/${id_company}/${id_person}`, data);
  }

  async update<T>(data: any): Promise<T> {
    return api.put(`/auction/update/${data?.id_auction}`, data);
  }

  async updateLot<T>(data: any): Promise<T> {
    return api.put(`/lot/auction/update/${data?.id_auction_lot}`, data);
  }

  async deleteLot<T>(id_person: number, id_auction_lot: number): Promise<T> {
    return api.delete(`/lot/auction/delete/${id_person}/${id_auction_lot}`);
  }

  async deleteBanner<T>(
    id_company: number,
    id_person: number,
    id_auction_banner: number,
  ): Promise<T> {
    return api.delete(
      `/banner/auction/delete/${id_company}/${id_person}/${id_auction_banner}`,
    );
  }

  async deleteVideo<T>(
    id_company: number,
    id_person: number,
    id_auction_videos: number,
  ): Promise<T> {
    return api.delete(
      `/video/auction/delete/${id_company}/${id_person}/${id_auction_videos}`,
    );
  }

  async deleteCatalog<T>(
    id_company: number,
    id_person: number,
    id_auction_catalog: number,
  ): Promise<T> {
    return api.delete(
      `/catalog/auction/delete/${id_company}/${id_person}/${id_auction_catalog}`,
    );
  }

  async deleteEntryOrder<T>(
    id_company: number,
    id_person: number,
    id_auction_catalog: number,
  ): Promise<T> {
    return api.delete(
      `/entryorder/auction/delete/${id_company}/${id_person}/${id_auction_catalog}`,
    );
  }
}
