import { createStyles, makeStyles } from '@material-ui/core';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#f0f0f0',
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(0, 1),
    },
    paper: {
      marginTop: 10,
      minHeight: '90vh',
    },
    form: {
      paddingTop: 30,
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      background: '#fff',
      display: 'flex',
      flexWrap: 'wrap',
      borderRadius: 30,
    },
    ImgBanner: {
      width: '100%',
      height: 300,
      borderRadius: 30,
      objectFit: 'cover',
    },
    row: {
      display: 'flex',
      padding: 5,
    },
    hammerIcon: {
      width: 50,
      backgroundColor: '#00af0e',
      borderRadius: 15,
      padding: 5,
      margin: 10,
    },
    hammerIconTitle: {
      backgroundColor: '#4cd295',
      borderRadius: 30,
      color: '#fff',
      padding: '5px 30px',
    },
    liveAuctionTitle: {
      textTransform: 'uppercase',
      background: '#f00',
      color: '#fff',
      padding: '5px 20px',
      borderRadius: 30,
    },
    pagination: {
      margin: 10,
      float: 'right',
    },
    avatarDetail: {
      margin: theme.spacing(0, 2),
      backgroundColor: theme.palette.info.dark,
    },
    textDetailPTitle: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 500,
      marginRight: 10,
      color: '#494646',
    },
    textDetailPInfo: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 400,
      color: '#686363',
    },
    textDetailA: {
      fontSize: 16,
      margin: '5px 0 0 0',
      fontWeight: 500,
      color: '#29367f',
      textDecoration: 'none',
    },
    infoNoInstanceText: {
      margin: '10px 0',
      color: '#2b3988',
      fontWeight: 400,
    },
    filterText: {
      color: '#696666',
      margin: 5,
    },
    table: {
      minWidth: 650,
    },
    tableLotsTitles: {
      background: '#01733f',
      padding: 5,
      color: '#fff',
      margin: '10px 10px 0px 10px',

      fontSize: 11,
      borderRadius: 30,
    },
    tableLotsInfo: {
      color: '#000',
      margin: '3px 10px 20px 10px',
      fontSize: 11,
      borderRadius: 30,
    },
    StatusWhatsApp: {
      margin: theme.spacing(3, 0),
      display: 'flex',
    },
    StatusWhatsAppConnected: {
      color: '#00af0e',
      marginRight: 10,
    },
    StatusWhatsAppDesconected: {
      color: '#ff0202',
    },
    appBar: {
      backgroundColor: '#fff',
      color: '#000',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    btn: {
      margin: 2,
    },
    aBtnOpenChat: {
      color: '#3f51b5',
      border: '1px solid rgba(63, 81, 181, 0.5)',
      fontWeight: 500,
      lineHeight: 1.75,
      borderRadius: '4px',
      padding: '10px 15px',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    chatSection: {
      width: '100%',
      height: '80vh',
    },
    headBG: {
      backgroundColor: '#e0e0e0',
    },
    borderRight500: {
      borderRight: '1px solid #e0e0e0',
    },
    messageArea: {
      height: '70vh',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    avatarMessageGrid: {
      justifyContent: 'center',
      alignContent: 'Center',
      display: 'flex',
    },
    messageLeft: {
      backgroundColor: '#f2f2f2',
      padding: theme.spacing(1, 2),
    },
    messageRight: {
      backgroundColor: '#c6d8c9',
      padding: theme.spacing(1, 2),
    },
    positionLeft: {
      float: 'left',
    },
    positionRight: {
      float: 'right',
    },
    btnEdit: {
      backgroundColor: '#4cd295',
      borderRadius: 30,
      padding: '5px 20px',
      color: '#fff',
      fontWeight: 700,
    },
    btnUpload: {
      background: '#4cd295',
      fontSize: 11,
      padding: '10px 15px',
      borderRadius: 30,
      color: '#fff',
      margin: 3,
    },
    btnRegister: {
      background: '#4cd295',
      fontSize: 28,
      padding: '15px 35px',
      margin: '50px 0',
      border: 0,
      borderRadius: 30,
      color: '#fff',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#ccc',
      },
    },
  }),
);

export default useStyles;
