/* eslint-disable camelcase */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { ICompany } from '../../providers/use-company';
import { api } from './config';

export class Company {
  async get<T>(id_company: number, id_person: number): Promise<T> {
    return api.get(`/company/show/${id_company}/${id_person}`);
  }

  async update<T>(data: ICompany): Promise<T> {
    return api.put(
      `/company/update/${data?.id_company}/${data?.id_person}`,
      data,
    );
  }

  async createPhotoLogo<T>(
    data: any,
    id_company: number,
    id_person: number,
  ): Promise<T> {
    return api.post(`/company/photo/logo/${id_company}/${id_person}`, data);
  }

  async deletePhotoLogo<T>(id_company: number, id_person: number): Promise<T> {
    return api.delete(`/company/photo/logo/delete/${id_company}/${id_person}`);
  }
}
