import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      width: '100%',
      height: '100%',
      background: '#0104a01c',
      position: 'fixed',
      zIndex: 10000,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
