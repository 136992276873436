import React from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import useStyles from '../../assets/styles/pages/forgotpasswordreset';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

const ForgotPasswordReset: React.FC = () => {
  const classeStyles = useStyles();
  return (
    <>
      <Header />
      <Container component="main" maxWidth="xs" id="forgot-password-reset">
        <CssBaseline />
        <div className={classeStyles.paper}>
          <Avatar className={classeStyles.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar senha
          </Typography>
          <form className={classeStyles.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="des_new_password"
              label="Digite sua nova senha"
              name="des_new_password"
              autoComplete="des_new_password"
              autoFocus
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={`btn-green ${classeStyles.submit}`}
            >
              Alterar
            </Button>
          </form>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ForgotPasswordReset;
